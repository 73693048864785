import { useIsDemoMode, useService, useTitle } from '@insight2profit/drive-app';
import { Stack } from '@mui/material';
import {
    GridRenderCellParams,
    GridRenderEditCellParams,
    GridRowParams,
    GridValueGetterParams,
    GridValueOptionsParams,
    getGridSingleSelectOperators,
} from '@mui/x-data-grid-premium';
import { alphaNumericSort } from '@price-for-profit/micro-services';
import { ProvideAnalyticsPrerequisites, ProvideAvailableAnalyticsReports } from 'packages/individualAnalytics';
import { useMemo, useState } from 'react';
import {
    DynamicEditableRenderCell,
    LONG_DATE_COLUMN,
    PERCENT_COLUMN,
    ProductPricesInterdependentColumnsRenderEditCell,
} from 'shared/components/app/DataGridCells';
import { UnitOfMeasureCell } from 'shared/components/app/DataGridCells/UnitOfMeasureCell';
import {
    ProductPricesApprovalActions,
    ProductPricesForeignCurrencyRenderCell,
} from 'shared/components/app/productPrices';
import { ProductPricesSubmitAction } from 'shared/components/app/productPrices/ProductPricesSubmitAction';
import { PageLayout } from 'shared/components/layouts';
import { KAI_UOM_VALUE_OPTION } from 'shared/constants';
import { useUserPermissions } from 'shared/hooks';
import { useAnalyticsAppInfo } from 'shared/hooks/use-analytics-app-info';
import { ProvideCustomerProductEngineBatchMetaDatas, useExchangeRates } from 'shared/providers';
import { useNonStandardAndStandardUomDropdown } from 'shared/queries/nonStandardAndStandardUomDropdownQuery';
import { useUomDropdown } from 'shared/queries/uomDropdownQuery';
import { IViewProductPrices, RemoveableGridColDef } from 'shared/types';
import {
    PositiveNonZero,
    formatNumberNoDecimal,
    modifiedByFormatter,
    productPricesAdjustMarginValueGetterCurried,
    productPricesForeignCurrencyAndUomValueSetterCurried,
    productPricesForeignCurrencyValueGetterCurried,
    productPricesQuantityUomValueGetterCurried,
    productPricesUomValueGetterCurried,
} from 'shared/utility';
import { ProductPriceUOMRenderEditCell } from '../../shared/components/app/DataGridCells/ProductPriceUOMRenderEditCell';
import { ProductPrices } from './productPrices';

export const pageName = 'Product Prices';

export function ProductPricesResolver() {
    const isDemoMode = useIsDemoMode();
    useTitle(`${pageName} - ${isDemoMode ? 'PMT' : 'Nouryon'}`);

    const { prerequisites, options } = useAnalyticsAppInfo();
    const [selectedProduct, setSelectedProduct] = useState<IViewProductPrices | null>(null);

    const columns = useProductPricesColumns({ setSelectedProduct });

    return (
        <PageLayout pageName={pageName}>
            <Stack gap={2}>
                <ProvideAnalyticsPrerequisites prerequisites={prerequisites} options={options}>
                    <ProvideAvailableAnalyticsReports>
                        {/* <ProductPricesSummaryResolver> */}
                        <ProvideCustomerProductEngineBatchMetaDatas>
                            <ProductPrices
                                columns={columns}
                                selectedProduct={selectedProduct}
                                setSelectedProduct={setSelectedProduct}
                            />
                        </ProvideCustomerProductEngineBatchMetaDatas>
                        {/* </ProductPricesSummaryResolver> */}
                    </ProvideAvailableAnalyticsReports>
                </ProvideAnalyticsPrerequisites>
            </Stack>
        </PageLayout>
    );
}

type useProductPricesColumnsProps = {
    setSelectedProduct: React.Dispatch<React.SetStateAction<IViewProductPrices | null>>;
};

function useProductPricesColumns({ setSelectedProduct }: useProductPricesColumnsProps) {
    const permissions = useUserPermissions();
    const { isForeignCurrency, exchangeRates } = useExchangeRates();
    const { uomValueOptions } = useUomDropdown();
    const { uomConversionService } = useService();
    const { uomValueOptions: allUomValueOptions } = useNonStandardAndStandardUomDropdown();

    const uomValueOptionsWithKai = useMemo(
        () => alphaNumericSort([KAI_UOM_VALUE_OPTION, ...uomValueOptions], ({ value }) => value),
        [uomValueOptions]
    );
    const allUomValueOptionsWithKai = useMemo(
        () => alphaNumericSort([KAI_UOM_VALUE_OPTION, ...allUomValueOptions], ({ value }) => value),
        [allUomValueOptions]
    );

    const columns: RemoveableGridColDef<IViewProductPrices>[] = useMemo(
        () => [
            {
                headerName: 'ID',
                field: 'massActionId',
                headerAlign: 'center',
                description: 'Unique ID identifier for each specific row in the application',
            },
            {
                headerName: "New Record's Id",
                field: 'newRecordId',
            },
            {
                headerName: 'Business Line',
                field: 'businessLine',
                width: 200,
                headerAlign: 'center',
                renderCell: params => params.row.businessLine?.toUpperCase(),
            },
            {
                headerName: 'Material',
                field: 'material',
                width: 250,
                headerAlign: 'center',
                renderCell: (params: GridRenderCellParams<string>) => {
                    return (
                        <a
                            href={'/#'}
                            onClick={event => {
                                event.preventDefault();
                                setSelectedProduct(params.row);
                            }}
                        >
                            {params.value}
                        </a>
                    );
                },
            },
            {
                headerName: 'Material Number',
                field: 'materialId',
                width: 200,
                headerAlign: 'center',
            },
            {
                headerName: 'Org Region',
                field: 'orgRegion',
                width: 200,
                headerAlign: 'center',
            },
            {
                headerName: 'Application',
                field: 'application',
                width: 200,
                headerAlign: 'center',
            },
            {
                headerName: 'Market Segment',
                field: 'marketSegment',
                width: 200,
                headerAlign: 'center',
            },
            {
                headerName: 'Peer Group',
                field: 'peerGroup',
                width: 200,
                headerAlign: 'center',
                description: 'Grouping of material, org region and application',
            },
            {
                headerName: 'Product Line',
                field: 'productLine',
                width: 200,
                headerAlign: 'center',
            },
            {
                headerName: 'Product Hierarchy 1',
                field: 'productHierarchy1',
                width: 200,
                headerAlign: 'center',
            },
            {
                headerName: 'Adjusted PH 2',
                field: 'adjustedPH2',
                width: 200,
                headerAlign: 'center',
                description: 'Group of materials within PH1 with similar margin',
            },
            {
                headerName: 'Product Hierarchy 3',
                field: 'productHierarchy3',
                width: 200,
                headerAlign: 'center',
            },
            {
                headerName: 'Elasticity Category',
                field: 'elasticityCategory',
                width: 200,
                headerAlign: 'center',
                description:
                    'Regressions calculated at the material level to identify how volume changes in response to a change in price historically',
            },
            {
                headerName: 'Product Reach Category',
                field: 'productReachCategory',
                width: 200,
                headerAlign: 'center',
                description:
                    'An index of how "popular" a product is defined by the number of unique customers that purchase that product compared to other similar products',
            },
            {
                headerName: 'Product Tier',
                field: 'productTier',
                width: 200,
                headerAlign: 'center',
                description:
                    "Revenue tier based on material's BL annual revenue, labeling largest materials Tier I and smallest Tier IV",
            },
            {
                headerName: 'PMI',
                field: 'pmi',
                width: 200,
                headerAlign: 'center',
                type: 'number',
                valueGetter: productPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
                renderCell: params => <ProductPricesForeignCurrencyRenderCell {...params} />,
                description:
                    "Purchasing Managers' Index value which measures the direction of economic trends in the manufacturing space",
            },
            {
                headerName: 'Ag Food Composite Index',
                field: 'agFoodCompositeIndex',
                width: 200,
                headerAlign: 'center',
                ...PERCENT_COLUMN,
                description:
                    'Composite index created by INSIGHT derrived from commodity price indices. Made up of 80% Agriculture commodity price index and 20% Food and Beverage commodity price index values',
            },
            {
                headerName: 'Total Adders',
                field: 'totalAdders',
                width: 200,
                headerAlign: 'center',
                ...PERCENT_COLUMN,
                description: 'Adjusts price corridor by a percentage based on product or customer attributes',
            },
            {
                headerName: 'VC Forecast Month 1 - 3',
                field: 'forecastedCost3Month',
                width: 200,
                headerAlign: 'center',
                type: 'number',
                valueGetter: (params: GridValueGetterParams<number, IViewProductPrices>) => {
                    const foreignCurrencyValueGetter = productPricesForeignCurrencyValueGetterCurried({
                        isForeignCurrency,
                        exchangeRates,
                    });
                    const foreignCurrencyValue = foreignCurrencyValueGetter(params);

                    const uomValueGetter = productPricesUomValueGetterCurried({ uomConversionService });
                    const uomValue = uomValueGetter({ ...params, value: foreignCurrencyValue });

                    return uomValue;
                },
                renderCell: params => <ProductPricesForeignCurrencyRenderCell {...params} />,
                description:
                    'From the 14th day of the month, reflects next 3 months using highest forecasted cost at org region level, selected from plant with highest variable cost',
            },
            {
                headerName: 'VC Forecast Month 4 - 6',
                field: 'forecastedCost6Month',
                width: 200,
                headerAlign: 'center',
                type: 'number',
                valueGetter: (params: GridValueGetterParams<number, IViewProductPrices>) => {
                    const foreignCurrencyValueGetter = productPricesForeignCurrencyValueGetterCurried({
                        isForeignCurrency,
                        exchangeRates,
                    });
                    const foreignCurrencyValue = foreignCurrencyValueGetter(params);

                    const uomValueGetter = productPricesUomValueGetterCurried({ uomConversionService });
                    const uomValue = uomValueGetter({ ...params, value: foreignCurrencyValue });

                    return uomValue;
                },
                renderCell: params => <ProductPricesForeignCurrencyRenderCell {...params} />,
                description:
                    'From 14th day of the month, reflects month 4 to 6 using highest forecasted cost at org region level, selected from plant with highest variable cost',
            },
            {
                headerName: 'VC Forecast Month 7 - 9',
                field: 'forecastedCost9Month',
                width: 200,
                headerAlign: 'center',
                type: 'number',
                valueGetter: (params: GridValueGetterParams<number, IViewProductPrices>) => {
                    const foreignCurrencyValueGetter = productPricesForeignCurrencyValueGetterCurried({
                        isForeignCurrency,
                        exchangeRates,
                    });
                    const foreignCurrencyValue = foreignCurrencyValueGetter(params);

                    const uomValueGetter = productPricesUomValueGetterCurried({ uomConversionService });
                    const uomValue = uomValueGetter({ ...params, value: foreignCurrencyValue });

                    return uomValue;
                },
                renderCell: params => <ProductPricesForeignCurrencyRenderCell {...params} />,
                description:
                    'From 14th day of the month, reflects month 7 to 9 using highest forecasted cost at org region level, selected from plant with highest variable cost',
            },
            {
                headerName: 'Floor Price (Last Month)',
                field: 'currentFloorPrice',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height green-header',
                type: 'number',
                valueGetter: (params: GridValueGetterParams<number, IViewProductPrices>) => {
                    const foreignCurrencyValueGetter = productPricesForeignCurrencyValueGetterCurried({
                        isForeignCurrency,
                        exchangeRates,
                    });
                    const foreignCurrencyValue = foreignCurrencyValueGetter(params);

                    const uomValueGetter = productPricesUomValueGetterCurried({ uomConversionService });
                    const uomValue = uomValueGetter({ ...params, value: foreignCurrencyValue });

                    return uomValue;
                },
                renderCell: params => <ProductPricesForeignCurrencyRenderCell {...params} />,
                description: 'Floor price from last month (highest floor price per material)',
            },
            {
                headerName: 'Current Minimum Price',
                field: 'currentMinimum',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height green-header',
                type: 'number',
                valueGetter: (params: GridValueGetterParams<number, IViewProductPrices>) => {
                    const foreignCurrencyValueGetter = productPricesForeignCurrencyValueGetterCurried({
                        isForeignCurrency,
                        exchangeRates,
                    });
                    const foreignCurrencyValue = foreignCurrencyValueGetter(params);

                    const uomValueGetter = productPricesUomValueGetterCurried({ uomConversionService });
                    const uomValue = uomValueGetter({ ...params, value: foreignCurrencyValue });

                    return uomValue;
                },
                renderCell: params => <ProductPricesForeignCurrencyRenderCell {...params} />,
                description: 'Current Minimum Price from marketing',
            },
            {
                headerName: 'Current Target Price',
                field: 'currentTarget',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height green-header',
                type: 'number',
                valueGetter: (params: GridValueGetterParams<number, IViewProductPrices>) => {
                    const foreignCurrencyValueGetter = productPricesForeignCurrencyValueGetterCurried({
                        isForeignCurrency,
                        exchangeRates,
                    });
                    const foreignCurrencyValue = foreignCurrencyValueGetter(params);

                    const uomValueGetter = productPricesUomValueGetterCurried({ uomConversionService });
                    const uomValue = uomValueGetter({ ...params, value: foreignCurrencyValue });

                    return uomValue;
                },
                renderCell: params => <ProductPricesForeignCurrencyRenderCell {...params} />,
                description: 'Current Target Price from marketing',
            },
            {
                headerName: 'Variable Cost',
                field: 'updatedCost',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height light-blue-header',
                type: 'number',
                valueGetter: (params: GridValueGetterParams<number, IViewProductPrices>) => {
                    const foreignCurrencyValueGetter = productPricesForeignCurrencyValueGetterCurried({
                        isForeignCurrency,
                        exchangeRates,
                    });
                    const foreignCurrencyValue = foreignCurrencyValueGetter(params);

                    const uomValueGetter = productPricesUomValueGetterCurried({ uomConversionService });
                    const uomValue = uomValueGetter({ ...params, value: foreignCurrencyValue });

                    return uomValue;
                },
                renderCell: params => <ProductPricesForeignCurrencyRenderCell {...params} />,
                description:
                    'Variable Cost Current Month at org region level, selected from the plant with highest variable cost',
            },
            {
                headerName: 'Updated Floor Price',
                field: 'updatedFloorPrice',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height light-blue-header',
                type: 'number',
                valueGetter: (params: GridValueGetterParams<number, IViewProductPrices>) => {
                    const foreignCurrencyValueGetter = productPricesForeignCurrencyValueGetterCurried({
                        isForeignCurrency,
                        exchangeRates,
                    });
                    const foreignCurrencyValue = foreignCurrencyValueGetter(params);

                    const uomValueGetter = productPricesUomValueGetterCurried({ uomConversionService });
                    const uomValue = uomValueGetter({ ...params, value: foreignCurrencyValue });

                    return uomValue;
                },
                renderCell: params => <ProductPricesForeignCurrencyRenderCell {...params} />,
                description: 'Floor price for the next pricing cycle',
            },
            {
                headerName: 'Recommended Minimum',
                field: 'recommendedMinimum',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height light-blue-header',
                hide: permissions.preferences.isShowCMPercentPreferred && !permissions.preferences.isShowPricePreferred,
                type: 'number',
                valueGetter: (params: GridValueGetterParams<number, IViewProductPrices>) => {
                    const foreignCurrencyValueGetter = productPricesForeignCurrencyValueGetterCurried({
                        isForeignCurrency,
                        exchangeRates,
                    });
                    const foreignCurrencyValue = foreignCurrencyValueGetter(params);

                    const uomValueGetter = productPricesUomValueGetterCurried({ uomConversionService });
                    const uomValue = uomValueGetter({ ...params, value: foreignCurrencyValue });

                    return uomValue;
                },
                renderCell: params => <ProductPricesForeignCurrencyRenderCell {...params} />,
                description: "Recommended minimum price outputted by INSIGHT's price corridor engine",
            },
            {
                headerName: 'Recommended Target',
                field: 'recommendedTarget',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height light-blue-header',
                hide: permissions.preferences.isShowCMPercentPreferred && !permissions.preferences.isShowPricePreferred,
                type: 'number',
                valueGetter: (params: GridValueGetterParams<number, IViewProductPrices>) => {
                    const foreignCurrencyValueGetter = productPricesForeignCurrencyValueGetterCurried({
                        isForeignCurrency,
                        exchangeRates,
                    });
                    const foreignCurrencyValue = foreignCurrencyValueGetter(params);

                    const uomValueGetter = productPricesUomValueGetterCurried({ uomConversionService });
                    const uomValue = uomValueGetter({ ...params, value: foreignCurrencyValue });

                    return uomValue;
                },
                renderCell: params => <ProductPricesForeignCurrencyRenderCell {...params} />,
                description: "Recommended target price outputted by INSIGHT's price corridor engine",
            },
            {
                headerName: 'Revised Minimum Price',
                field: 'regionalRevisedMinimum',
                width: 200,
                headerAlign: 'center',
                preProcessEditCellProps: PositiveNonZero,
                headerClassName: 'color-header-full-height light-blue-header',
                editable: permissions.data.isRegionalRevisedMinimumWriteAllowed,
                customFieldIsReadAllowed: permissions.data.isRegionalRevisedMinimumReadAllowed,
                hide: permissions.preferences.isShowCMPercentPreferred && !permissions.preferences.isShowPricePreferred,
                type: 'number',
                valueGetter: (params: GridValueGetterParams<number, IViewProductPrices>) => {
                    const foreignCurrencyValueGetter = productPricesForeignCurrencyValueGetterCurried({
                        isForeignCurrency,
                        exchangeRates,
                    });
                    const foreignCurrencyValue = foreignCurrencyValueGetter(params);

                    const uomValueGetter = productPricesUomValueGetterCurried({ uomConversionService });
                    const uomValue = uomValueGetter({ ...params, value: foreignCurrencyValue });

                    return uomValue;
                },
                valueSetter: productPricesForeignCurrencyAndUomValueSetterCurried({
                    isForeignCurrency,
                    exchangeRates,
                    priceColumnName: 'regionalRevisedMinimum',
                }),
                renderCell: params => <ProductPricesForeignCurrencyRenderCell {...params} />,
                renderEditCell: params => <ProductPricesInterdependentColumnsRenderEditCell {...params} />,
            },
            {
                headerName: 'Revised Target Price',
                field: 'regionalRevisedTarget',
                width: 200,
                headerAlign: 'center',
                preProcessEditCellProps: PositiveNonZero,
                headerClassName: 'color-header-full-height light-blue-header',
                editable: permissions.data.isRegionalRevisedTargetWriteAllowed,
                customFieldIsReadAllowed: permissions.data.isRegionalRevisedTargetReadAllowed,
                hide: permissions.preferences.isShowCMPercentPreferred && !permissions.preferences.isShowPricePreferred,
                type: 'number',
                valueGetter: (params: GridValueGetterParams<number, IViewProductPrices>) => {
                    const foreignCurrencyValueGetter = productPricesForeignCurrencyValueGetterCurried({
                        isForeignCurrency,
                        exchangeRates,
                    });
                    const foreignCurrencyValue = foreignCurrencyValueGetter(params);

                    const uomValueGetter = productPricesUomValueGetterCurried({ uomConversionService });
                    const uomValue = uomValueGetter({ ...params, value: foreignCurrencyValue });

                    return uomValue;
                },
                valueSetter: productPricesForeignCurrencyAndUomValueSetterCurried({
                    isForeignCurrency,
                    exchangeRates,
                    priceColumnName: 'regionalRevisedTarget',
                }),
                renderCell: params => <ProductPricesForeignCurrencyRenderCell {...params} />,
                renderEditCell: params => <ProductPricesInterdependentColumnsRenderEditCell {...params} />,
            },
            {
                headerName: 'Recommended Minimum Adjusted Margin %',
                field: 'recommendedMinimumCMPercent',
                width: 300,
                headerAlign: 'center',
                ...PERCENT_COLUMN,
                headerClassName: 'color-header-full-height light-blue-header',
                description:
                    "Adjusted Margin does not equal Contribution Margin. Adjusted Margin % for INSIGHT's engine recommended minimum price. Calculated as ([Recommended Minimum]-[Variable Cost])/[Recommended Minimum]",
            },
            {
                headerName: 'Recommended Target Adjusted Margin %',
                field: 'recommendedTargetCMPercent',
                width: 300,
                headerAlign: 'center',
                ...PERCENT_COLUMN,
                headerClassName: 'color-header-full-height light-blue-header',
                description:
                    "Adjusted Margin does not equal Contribution Margin. Adjusted Margin % for INSIGHT's engine recommended target price.  Calculated as ([Recommended Target]-[Variable Cost])/[Recommended Target]",
            },
            {
                headerName: 'Revised Minimum Adjusted Margin %',
                field: 'regionalRevisedMinimumCMPercent',
                width: 350,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height light-blue-header',
                editable: permissions.data.isRegionalRevisedMinimumWriteAllowed,
                ...PERCENT_COLUMN,
                valueGetter: productPricesAdjustMarginValueGetterCurried({ priceColumnName: 'regionalRevisedMinimum' }),
                renderEditCell: params => <ProductPricesInterdependentColumnsRenderEditCell {...params} />,
                renderCell: params => <DynamicEditableRenderCell {...params} />,
                customFieldIsReadAllowed: permissions.data.isRegionalRevisedMinimumReadAllowed,
                description:
                    'Adjusted Margin does not equal Contribution Margin. Adjusted Margin % for inputted minimum price. Calculated as ([Revised Minimum Price]-[Variable Cost])/[Revised Minimum Price]',
            },
            {
                headerName: 'Revised Target Adjusted Margin %',
                field: 'regionalRevisedTargetCMPercent',
                width: 300,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height light-blue-header',
                editable: permissions.data.isRegionalRevisedTargetWriteAllowed,
                ...PERCENT_COLUMN,
                valueGetter: productPricesAdjustMarginValueGetterCurried({ priceColumnName: 'regionalRevisedTarget' }),
                renderEditCell: params => <ProductPricesInterdependentColumnsRenderEditCell {...params} />,
                renderCell: params => <DynamicEditableRenderCell {...params} />,
                customFieldIsReadAllowed: permissions.data.isRegionalRevisedTargetReadAllowed,
                description:
                    'Adjusted Margin does not equal Contribution Margin. Adjusted Margin % for inputted target price. Calculated as ([Revised Target Price]-[Variable Cost])/[Revised Target Price]',
            },
            {
                headerName: 'UOM',
                field: 'uom',
                type: 'singleSelect',
                width: 200,
                headerAlign: 'center',
                editable: true,
                headerClassName: 'color-header-full-height light-blue-header',
                filterOperators: getGridSingleSelectOperators().filter(operator =>
                    ['is', 'not'].includes(operator.value)
                ),
                valueOptions: (params: GridValueOptionsParams) => {
                    // for grid filter only
                    if (!params.row) {
                        return allUomValueOptionsWithKai;
                    }
                    // This is replaced in ProductPriceUOMRenderEditCell
                    return uomValueOptionsWithKai;
                },
                description: 'Unit of Measure use for pricing',
                renderCell: params => <UnitOfMeasureCell {...params} />,
                renderEditCell: (params: GridRenderEditCellParams<string>) => {
                    return <ProductPriceUOMRenderEditCell {...params} />;
                },
            },
            {
                headerName: 'TTM Revenue FCA',
                field: 'ttmRevenue',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height light-blue-header',
                type: 'number',
                valueGetter: productPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
                renderCell: params => <ProductPricesForeignCurrencyRenderCell {...params} />,
                description: 'Sum of Net Sales minus allocated freight cost by Material over the last 12 months',
            },
            {
                headerName: 'TTM Quantity',
                field: 'ttmQuantityKai',
                width: 200,
                headerAlign: 'center',
                type: 'number',
                valueFormatter: params => formatNumberNoDecimal(params.value),
                valueGetter: (params: GridValueGetterParams<number, IViewProductPrices>) => {
                    const uomValueGetter = productPricesQuantityUomValueGetterCurried({ uomConversionService });
                    return uomValueGetter(params);
                },
                headerClassName: 'color-header-full-height light-blue-header',
                description: 'Sum of Quantity by Material over the last 12 months',
            },
            {
                headerName: 'Status',
                field: 'status',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height blue-header',
                description: 'Shows when price requires review, requires approval, or is approved',
            },
            {
                headerName: 'Approver',
                field: 'approver',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height blue-header',
                description: 'Role of price approver',
            },
            {
                headerName: 'Last Edited By',
                field: 'modifiedBy',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height blue-header',
                valueFormatter: modifiedByFormatter,
                description: 'Last person to edit price in application',
            },
            {
                headerName: 'Last Edited Date',
                field: 'effectiveStart',
                width: 200,
                headerAlign: 'center',
                ...LONG_DATE_COLUMN,
                headerClassName: 'color-header-full-height blue-header',
                description: 'Last time the price was edited in application',
            },
            {
                headerName: 'Price Record Type',
                field: 'priceRecordType',
                width: 200,
                headerAlign: 'center',
                type: 'singleSelect',
                filterOperators: getGridSingleSelectOperators().filter(operator =>
                    ['is', 'not'].includes(operator.value)
                ),
                valueOptions: ['Needs Review', 'Price Up', 'Price Down', 'Price Accepted'],
                headerClassName: 'color-header-full-height blue-header',
                description:
                    'Categorizes price revision relative to engine recommendation (Price Up, Price Down, Price Accepted)',
            },
            {
                headerName: 'Data Source',
                field: 'dataSourceDisplay',
                width: 200,
                headerAlign: 'center',
                align: 'center',
                description: 'Indicates rows from pricing tool vs rows added by users',
            },
            {
                headerName: 'Submit Price',
                field: 'action-submit',
                type: 'actions',
                hide: false,
                filterable: false,
                pinnable: false,
                width: 125,
                headerAlign: 'center',
                getActions: (params: GridRowParams<IViewProductPrices>): JSX.Element[] => [
                    <ProductPricesSubmitAction key={`submit-button-${params.row.id}`} params={params} />,
                ],
            },
            {
                headerName: 'Approve',
                field: 'approval-actions',
                type: 'actions',
                hide: false,
                filterable: false,
                pinnable: false,
                width: 125,
                headerAlign: 'center',
                getActions: (params: GridRowParams<IViewProductPrices>): JSX.Element[] =>
                    ProductPricesApprovalActions({ params }),
                customFieldIsReadAllowed: permissions.userApproverTier >= 3,
            },
        ],
        [
            permissions,
            setSelectedProduct,
            exchangeRates,
            isForeignCurrency,
            uomConversionService,
            allUomValueOptionsWithKai,
            uomValueOptionsWithKai,
        ]
    );

    const filteredColumns = columns.filter(column => column.customFieldIsReadAllowed !== false);

    return filteredColumns;
}
