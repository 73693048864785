import { Box, Button } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid-premium';
import { productPriceStatuses } from 'shared/constants';
import { useUserPermissions } from 'shared/hooks';
import { useProductPricesSubmitMutation } from 'shared/mutations';
import { useCustomerProductEngineBatchMetaDatas } from 'shared/providers';
import { IViewProductPrices } from 'shared/types';

export function ProductPricesSubmitAction({ params }: { params: GridRowParams<IViewProductPrices> }) {
    const { isInProgress, isLoading: batchMetadataIsLoading } = useCustomerProductEngineBatchMetaDatas();

    const { userApproverTier } = useUserPermissions();

    const { onSubmit, isLoading } = useProductPricesSubmitMutation();

    if (params.row.status === 'Regional Approved' || params.row.status === 'Approved') return <Box>Submitted</Box>;

    const isMissingPrices = !params.row.regionalRevisedMinimum || !params.row.regionalRevisedTarget;
    const isSubmitAllowed =
        !isMissingPrices &&
        !isInProgress(params.row.businessLine) &&
        !batchMetadataIsLoading &&
        params.row.status === productPriceStatuses.NEEDS_REVIEW &&
        userApproverTier >= 2;

    return (
        <Button
            variant='contained'
            onClick={() => {
                onSubmit(params.row);
            }}
            disabled={isLoading || !isSubmitAllowed}
        >
            Submit
        </Button>
    );
}
