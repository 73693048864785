import { GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-premium';
import { longDateValueFormatter, percentValueFormatter, shortDateValueFormatter } from 'shared/utility';

export const PERCENT_COLUMN = {
    type: 'number',
    valueFormatter: percentValueFormatter,
};

export const SHORT_DATE_COLUMN = {
    type: 'date',
    valueFormatter: shortDateValueFormatter,
};

export const LONG_DATE_COLUMN = {
    type: 'date',
    valueFormatter: longDateValueFormatter,
};

export function DynamicEditableRenderCell<T extends GridValidRowModel>(params: GridRenderCellParams<any, T, any>) {
    return (
        <div className={params.colDef.editable && params.isEditable ? 'editable-cell' : undefined}>
            {params.colDef.valueFormatter
                ? params.colDef.valueFormatter({ ...params, value: params.value })
                : params.value}
        </div>
    );
}
