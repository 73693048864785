import { CLIENT_ID, useService, useUser } from '@insight2profit/drive-app';
import { ServerSideState } from '@price-for-profit/data-grid';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useUserPermissions } from 'shared/hooks';
import { IProductPricesApprovalMassActionParameters, IProductPricesMassActionForm } from 'shared/types';

export type ProductPricesApprovalMassActionMutationParams = {
    state: ServerSideState;
    parameters: IProductPricesApprovalMassActionParameters;
};

export type UseProductPricesApprovalMassActionMutationProps = {
    state: ServerSideState;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

export function useProductPricesApprovalMassActionMutation({
    state,
    onSuccess,
    onError,
}: UseProductPricesApprovalMassActionMutationProps) {
    const { productPricesService, activityTrackingService, appConfig } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const { permittedRowLevels, userApproverTier } = useUserPermissions();
    const user = useUser();

    const mutation = useMutation<void, Error, ProductPricesApprovalMassActionMutationParams>({
        mutationKey: ['productPricesApprovalMassAction'],
        mutationFn: async ({ state, parameters }) => {
            return await productPricesService.approvalMassAction({
                state,
                permittedRowLevels,
                parameters,
                userApproverTier,
            });
        },
        onSuccess: () => {
            enqueueSnackbar('Mass action successfully completed', { variant: 'success' });
            onSuccess?.();
            activityTrackingService.trackStandardUserActivityEvent({
                action: 'ApproveMassAction',
                user: {
                    displayName: user.displayName,
                    email: user.email,
                    id: user.id,
                },
                payload: {
                    pageName: 'ProductPrices',
                },
                correlationId: '',
                eventType: 'ButtonClick',
                applicationId: appConfig.appId,
                clientId: CLIENT_ID,
            });
        },
        onError: error => {
            enqueueSnackbar(error.message, { variant: 'error' });
            onError?.(error);
        },
    });

    const onSubmit = (form: IProductPricesMassActionForm) => {
        mutation.mutate({
            state,
            parameters: productPricesFormToApprovalParameterMapper(form),
        });
    };

    return { onSubmit, isLoading: mutation.isLoading };
}

const productPricesFormToApprovalParameterMapper = (
    form: IProductPricesMassActionForm
): IProductPricesApprovalMassActionParameters =>
    ({
        Type: form.type as IProductPricesApprovalMassActionParameters['Type'],
        Comment: form.comment,
        ModifiedBy: form.modifiedBy,
    } as IProductPricesApprovalMassActionParameters);
