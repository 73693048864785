import { useService, useUser } from '@insight2profit/drive-app';
import { ServerSideState } from '@price-for-profit/data-grid';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useUserPermissions } from 'shared/hooks';

export type TemporalCustomerPricesSubmitMassActionMutationParams = {
    state: ServerSideState;
};

export type UseTemporalCustomerPricesSubmitMassActionMutationProps = {
    state: ServerSideState;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

export function useTemporalCustomerPricesSubmitMassActionMutation({
    state,
    onSuccess,
    onError,
}: UseTemporalCustomerPricesSubmitMassActionMutationProps) {
    const { temporalCustomerPricesService } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const { customerPricesPermittedRowLevels, userApproverTier } = useUserPermissions();
    const user = useUser();

    const mutation = useMutation<void, Error, TemporalCustomerPricesSubmitMassActionMutationParams>({
        mutationKey: ['temporalCustomerPricesSubmitMassAction'],
        mutationFn: async ({ state }) => {
            return await temporalCustomerPricesService.submitMassAction({
                state,
                customerPricesPermittedRowLevels,
                userApproverTier,
                user,
            });
        },
        onSuccess: () => {
            enqueueSnackbar('Mass action successfully completed', { variant: 'success' });
            onSuccess?.();
        },
        onError: error => {
            enqueueSnackbar(error.message, { variant: 'error' });
            onError?.(error);
        },
    });

    const onSubmit = () => {
        mutation.mutate({
            state,
        });
    };

    return { onSubmit, isLoading: mutation.isLoading };
}
