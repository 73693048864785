import AddIcon from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';
import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
} from '@mui/x-data-grid-premium';
import { GridToolbarLayoutsButton, ServerSideState } from '@price-for-profit/data-grid';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserPermissions } from 'shared/hooks';
import { GridToolbarCustomerProductEngineBatchProcessAction } from './GridToolbarCustomerProductEngineBatchProcessAction';
import { ProductPricesToggleCurrency } from './ProductPricesToggleCurrency';
import { ProductPricesMassActionModal } from './massActionModal/ProductPricesMassActionModal';

type ProductPricesCustomToolbarProps = {
    state: ServerSideState;
    invalidate: () => void;
    rowCount: number;
    regionalCurrencyLocked?: boolean;
};

export function ProductPricesCustomToolbar({
    state,
    invalidate,
    rowCount,
    regionalCurrencyLocked,
}: ProductPricesCustomToolbarProps) {
    const {
        data: { isRegionalRevisedMinimumWriteAllowed, isRegionalRevisedTargetWriteAllowed, isAddProductPriceAllowed },
    } = useUserPermissions();

    const navigate = useNavigate();
    const { pathname } = useLocation();

    const isMassActionAllowed = isRegionalRevisedMinimumWriteAllowed || isRegionalRevisedTargetWriteAllowed;

    const handleClick = () => {
        navigate(pathname.concat('/add-record'));
    };

    return (
        <Box display='flex' flexDirection='column' justifyContent='center'>
            <Box display='flex'>
                <ProductPricesToggleCurrency label={'Regional Currency'} isLocked={regionalCurrencyLocked} />
                <GridToolbarCustomerProductEngineBatchProcessAction />
            </Box>
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarLayoutsButton localStorageName='nouryon-pmt-product' />
                {isMassActionAllowed && (
                    <ProductPricesMassActionModal state={state} invalidate={invalidate} rowCount={rowCount} />
                )}
                {isAddProductPriceAllowed && (
                    <Button color='primary' startIcon={<AddIcon />} size='small' onClick={handleClick}>
                        Add record
                    </Button>
                )}
            </GridToolbarContainer>
        </Box>
    );
}
