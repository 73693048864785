export const APP_NAME = 'Nouryon PMT App' as const;

// DAS
export const DATABASE_LABEL = 'main' as const;

export const REGION = {
    pfEmea: 'PF EMEA',
    pfEurope: 'PF EUROPE',
    pfMea: 'PF MEA',
    pfIndia: 'PF INDIA',
    pfNeAsia: 'PF NE ASIA',
    pfNorthAmerica: 'PF NORTH AMERICA',
    pfSeAsia: 'PF SE ASIA',
    pfSouthAmerica: 'PF SOUTH AMERICA',
    tsAsiaPacific: 'TS ASIA PACIFIC',
    tsEmea: 'TS EMEA',
    tsNorthAmerica: 'TS NORTH AMERICA',
    tsSouthAmerica: 'TS SOUTH AMERICA',
} as const;

export const MARKET_SEGMENT = {
    notAssigned: 'NOT ASSIGNED',
    tsCatalyst: 'TS CATALYST',
    tsCoatings: 'TS COATINGS',
    tsConstruction: 'TS CONSTRUCTION',
    tsElectronics: 'TS ELECTRONICS',
    tsFoundry: 'TS FOUNDRY',
    tsHpmo: 'TS HPMO',
    tsOilWellService: 'TS OIL WELL SERVICE',
    tsOther: 'TS OTHER',
    tsPackaging: 'TS PACKAGING',
    tsPharma: 'TS PHARMA',
    tsPorousCeramics: 'TS POROUS CERAMICS',
} as const;

export const BUSINESS_LINE = {
    agAndFood: 'AGRICULTURE AND FOOD',
    inorganicSpecialties: 'INORGANIC',
    paintsAndCoatings: 'PAINTS AND COATINGS',
    naturalResources: 'NATURAL RESOURCES',
} as const;

export const SUPPORT_URL = 'https://insight2profit.atlassian.net/servicedesk/customer/portal/21';

export const VIEW_MATERIAL_NON_STANDARD_UOM_CONVERSION = 'vwMaterialNonStandardUOMConversion' as const;

export const UOM_KAI = 'KAI' as const;
export const UOM_KG = 'KG' as const;

export const COMMON_APPROVER = {
    NONE: 'None',
    PRODUCT_LINE_MANAGEMENT: 'Product Line Management',
    MARKETING_MANAGER: 'Marketing Manager',
};
