import { useService } from '@insight2profit/drive-app';
import { Box, CircularProgress, SelectChangeEvent, Tooltip } from '@mui/material';
import {
    GridEditSingleSelectCell,
    GridEditSingleSelectCellProps,
    GridRenderEditCellParams,
    useGridApiContext,
} from '@mui/x-data-grid-premium';
import { alphaNumericSort } from '@price-for-profit/micro-services';
import { useMemo } from 'react';
import { UnitOfMeasureCell } from 'shared/components/app/DataGridCells/UnitOfMeasureCell';
import { KAI_UOM_VALUE_OPTION } from 'shared/constants';
import { useMaterialUom } from 'shared/hooks';
import { useExchangeRates } from 'shared/providers';

export const ProductPriceUOMRenderEditCell = (params: GridRenderEditCellParams) => {
    const apiRef = useGridApiContext();
    const { uomConversionService } = useService();
    const { isForeignCurrency } = useExchangeRates();
    const { materialUomList, materialUomValueOptions, isLoading: isMaterialUomLoading } = useMaterialUom(
        params.row.materialId
    );

    const materialUomValueOptionsWithKai = useMemo(
        () => alphaNumericSort([KAI_UOM_VALUE_OPTION, ...materialUomValueOptions], ({ value }) => value),
        [materialUomValueOptions]
    );

    const lastSelectedUom = params.value;

    const handleValueChange: GridEditSingleSelectCellProps['onValueChange'] = async (
        _event: SelectChangeEvent<unknown>,
        newUom: string
    ) => {
        const uomBeforeEditingBegan = apiRef.current.getCellValue(params.row.id, 'uom');
        const isNoChange = lastSelectedUom === newUom;
        const isNewRow = params.row.isNew;
        const isFieldThatImpactsOtherFields = params.field in relatedEditableFields;

        if (isNoChange || isNewRow || !isFieldThatImpactsOtherFields || isForeignCurrency) return;

        for (const relatedEditableField of relatedEditableFields[params.field]) {
            const oldRelatedEditableFieldValue = apiRef.current.getCellValue(params.row.id, relatedEditableField);

            if (!oldRelatedEditableFieldValue) break;

            const newRelatedEditableFieldValue = (() => {
                // Non Standard UOM - Start
                const nonStandardConversion = uomConversionService.nonStandardConversion({
                    oldUom: uomBeforeEditingBegan,
                    newUom,
                    materialUomList,
                    value: oldRelatedEditableFieldValue,
                    kaiKgConversionFactor: params.row.materialKaiKgConversionFactor,
                });

                if (nonStandardConversion) {
                    return nonStandardConversion;
                }
                // Non Standard UOM - End

                if (uomBeforeEditingBegan === newUom) {
                    return oldRelatedEditableFieldValue;
                }

                if (uomBeforeEditingBegan !== 'KAI' && newUom === 'KAI') {
                    return uomConversionService.convertUomToKai({
                        uomPrice: oldRelatedEditableFieldValue,
                        kgConversion: params.row.kgConversion,
                        materialKaiKgConversionFactor: params.row.materialKaiKgConversionFactor,
                    });
                }
                const matchingUom = uomConversionService.matchingStandardUom({
                    uom: newUom,
                    materialUomList,
                });
                if (!matchingUom) throw Error('Cannot find new Uom');
                if (uomBeforeEditingBegan === 'KAI' && newUom !== 'KAI') {
                    return uomConversionService.convertKaiToUom({
                        kaiPrice: oldRelatedEditableFieldValue,
                        kgConversion: matchingUom.kgConversion ?? 1,
                        materialKaiKgConversionFactor: params.row.materialKaiKgConversionFactor,
                    });
                }
                if (uomBeforeEditingBegan !== 'KAI' && newUom !== 'KAI') {
                    return uomConversionService.convertUomToUom({
                        uomPrice: oldRelatedEditableFieldValue,
                        fromKgConversion: params.row.kgConversion,
                        toKgConversion: matchingUom.kgConversion ?? 1,
                    });
                }
                return oldRelatedEditableFieldValue;
            })();

            await apiRef.current.setEditCellValue({
                id: params.row.id,
                field: relatedEditableField,
                value: newRelatedEditableFieldValue,
            });
        }
    };

    if (isForeignCurrency) return <UnitOfMeasureCell {...params} />;

    if (isMaterialUomLoading)
        return (
            <Box display='flex' justifyContent={'space-between'} alignItems='center' width='100%' p={2}>
                <Box>{params.value}</Box>
                <CircularProgress size={20} />
            </Box>
        );

    return (
        <Tooltip
            title='Users must click off the line or press enter to view UoM conversion for all fields'
            placement='top-end'
            arrow
            open={true}
            PopperProps={{
                style: {
                    zIndex: 0,
                },
            }}
        >
            <Box
                sx={{
                    minWidth: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <GridEditSingleSelectCell
                    {...params}
                    colDef={{
                        ...params.colDef,
                        valueOptions: materialUomValueOptionsWithKai,
                    }}
                    onValueChange={handleValueChange}
                />
            </Box>
        </Tooltip>
    );
};

const relatedEditableFields: Record<string, string[]> = {
    uom: ['regionalRevisedMinimum', 'regionalRevisedTarget'],
};
