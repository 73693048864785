import { useService } from '@insight2profit/drive-app';
import { Box, LinearProgress } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { useI2pDataGridEdit, useI2pServerDataGrid } from '@price-for-profit/data-grid';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AlertModal } from 'shared/components/app/alertModal';
import {
    TemporalCustomerPricesCustomToolbar,
    TemporalCustomerPricesExceptionsModal,
} from 'shared/components/app/temporalCustomerPrices';
import { TemporalCustomerPricesQuoteModal } from 'shared/components/app/temporalCustomerPrices/temporalCustomerPricesQuote/TemporalCustomerPricesQuoteModal';
import { REGIONAL_CURRENCY_NOT_EDITABLE } from 'shared/constants';
import { DATA_GRID_STYLE, HEADER_HEIGHT } from 'shared/constants/dataGrid';
import { QUERY_KEYS } from 'shared/constants/queryKeys';
import { useUserPermissions } from 'shared/hooks';
import { useTrackPageView } from 'shared/hooks/use-track-page-view';
import { ProvidePricesInvalidator, useExchangeRates, useSelectedTemporalCustomerPrice } from 'shared/providers';
import { IViewTemporalCustomerPricesPage } from 'shared/types';
import { createCustomerPricesInitialState, useTemporalCustomerPricesColumns } from './components';
import { TemporalCustomerPriceDrawer } from './temporalCustomerPriceDrawer/TemporalCustomerPriceDrawer';

type CustomerPricesProps = {
    user: app.UserInfo;
};

export function TemporalCustomerPrices({ user }: CustomerPricesProps) {
    const [mutationLoading, setMutationLoading] = useState(false);
    const { temporalCustomerPricesService, customerPricesPersistedAttributesService } = useService();
    const { isForeignCurrency } = useExchangeRates();
    const { customerPricesPermittedRowLevels } = useUserPermissions();
    const { enqueueSnackbar } = useSnackbar();
    const [errorModalMessage, setErrorModalMessage] = useState('');
    const { openDrawer, status } = useSelectedTemporalCustomerPrice();
    const columns = useTemporalCustomerPricesColumns({ openDrawer });
    const [searchParameters] = useSearchParams();
    const queryClient = useQueryClient();

    useTrackPageView('temporalCustomerPrices', user?.displayName || '', user?.email || '', user?.id || '');

    const idString = searchParameters.get('ids');
    const idArray = idString ? idString.split('|') : [];

    const filterModelItems = idArray.map(anId => {
        return {
            id: anId,
            columnField: 'newRecordId',
            operatorValue: 'contains',
            value: anId,
        };
    });

    const { initialState, dataGridInitialState, rowsPerPageOptions } = createCustomerPricesInitialState({
        filterModelItems,
    });

    const { getDataGridProps, invalidate, state } = useI2pServerDataGrid<IViewTemporalCustomerPricesPage>({
        columns,
        name: QUERY_KEYS.temporalCustomerPricesPage,
        getData: async state => {
            return await temporalCustomerPricesService.getGridData({ state, customerPricesPermittedRowLevels });
        },
        initialState,
        dataGridInitialState,
        rowsPerPageOptions,
    });

    const { getDataGridEditProps } = useI2pDataGridEdit<IViewTemporalCustomerPricesPage>({
        onEdit: async (newViewRow, oldViewRow) => {
            if (JSON.stringify(oldViewRow) === JSON.stringify(newViewRow)) {
                enqueueSnackbar('No edit made', { variant: 'warning' });
                setMutationLoading(false);

                return oldViewRow;
            }

            setMutationLoading(true);
            if (
                oldViewRow.documentCurrency !== newViewRow.documentCurrency ||
                oldViewRow.priceTypeAttributes !== newViewRow.priceTypeAttributes ||
                oldViewRow.uom !== newViewRow.uom
            ) {
                await customerPricesPersistedAttributesService.softEditPersistedAttributes({
                    isForeignCurrency,
                    newViewRow,
                    oldViewRow,
                    userDisplayName: user?.displayName,
                    userEmail: user?.email || '',
                });
            }

            const customerPricesResult = await temporalCustomerPricesService.softEditGridRowData({
                isForeignCurrency,
                newViewRow,
                oldViewRow,
                user,
                queryClient,
            });

            invalidate();
            enqueueSnackbar('Edit saved', { variant: 'success' });
            setErrorModalMessage('');
            setMutationLoading(false);

            return customerPricesResult.newViewRow;
        },
        onError: (error: Error) => {
            invalidate();
            enqueueSnackbar(`Edit failed: ${error.message}`, { variant: 'error' });
            setErrorModalMessage(error.message);
        },
    });

    const dataGridProps = getDataGridProps();
    const dataGridEditProps = getDataGridEditProps();

    const handleProcessRowUpdateError = (error: Error) => {
        enqueueSnackbar(`${error.message}`, { variant: 'error' });
    };

    return (
        <Box height='calc(100vh - 300px)'>
            <ProvidePricesInvalidator invalidateQuery={invalidate}>
                <DataGridPremium
                    {...dataGridProps}
                    {...dataGridEditProps}
                    sx={DATA_GRID_STYLE}
                    disableSelectionOnClick
                    components={{
                        Toolbar: TemporalCustomerPricesCustomToolbar,
                        LoadingOverlay: LinearProgress,
                    }}
                    componentsProps={{
                        panel: {
                            sx: {
                                '& .MuiDataGrid-filterFormColumnInput': {
                                    width: 'auto',
                                },
                            },
                        },
                        toolbar: {
                            state,
                            invalidate,
                            rowCount: dataGridProps.rowCount,
                            mutationLoading,
                        },
                    }}
                    onRowEditStart={() => setMutationLoading(true)}
                    onRowEditStop={() => setMutationLoading(false)}
                    editMode='row'
                    disableVirtualization={false}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                    headerHeight={HEADER_HEIGHT}
                    getRowId={row => row.id}
                    isCellEditable={params => {
                        if (REGIONAL_CURRENCY_NOT_EDITABLE.includes(params.field)) {
                            return !isForeignCurrency;
                        }
                        return true;
                    }}
                />
            </ProvidePricesInvalidator>
            {<TemporalCustomerPriceDrawer />}
            {<TemporalCustomerPricesExceptionsModal />}
            {
                <TemporalCustomerPricesQuoteModal
                    key={`quote-modal-${status?.toString() || 'none'}`}
                    state={state}
                    rows={dataGridProps.rows as IViewTemporalCustomerPricesPage[]}
                />
            }
            <AlertModal
                onClose={() => setErrorModalMessage('')}
                open={!!errorModalMessage}
                title={'Edit not allowed!'}
                message={errorModalMessage}
            />
        </Box>
    );
}
