import { CLIENT_ID, useService, useUser } from '@insight2profit/drive-app';
import { ServerSideState } from '@price-for-profit/data-grid';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useUserPermissions } from 'shared/hooks';
import { ICustomerPricesMassActionForm, ITemporalCustomerPricesApprovalMassActionParameters } from 'shared/types';

export type TemporalCustomerPricesApprovalMassActionMutationParams = {
    state: ServerSideState;
    parameters: ITemporalCustomerPricesApprovalMassActionParameters;
};

export type UseTemporalCustomerPricesApprovalMassActionMutationProps = {
    state: ServerSideState;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

export function useTemporalCustomerPricesApprovalMassActionMutation({
    state,
    onSuccess,
    onError,
}: UseTemporalCustomerPricesApprovalMassActionMutationProps) {
    const { temporalCustomerPricesService, activityTrackingService, appConfig } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const { customerPricesPermittedRowLevels, userApproverTier } = useUserPermissions();
    const user = useUser();

    const mutation = useMutation<void, Error, TemporalCustomerPricesApprovalMassActionMutationParams>({
        mutationKey: ['temporalCustomerPricesApprovalMassAction'],
        mutationFn: async ({ state, parameters }) => {
            return await temporalCustomerPricesService.approvalMassAction({
                state,
                customerPricesPermittedRowLevels,
                parameters: {
                    ...parameters,
                    UpdatedByDisplayName: user.displayName || '',
                    UpdatedByEmail: user.email || '',
                    UpdatedByUserId: user.id || '',
                },
                userApproverTier,
            });
        },
        onSuccess: () => {
            enqueueSnackbar('Mass action successfully completed', { variant: 'success' });
            onSuccess?.();
            activityTrackingService.trackStandardUserActivityEvent({
                action: 'ApproveMassAction',
                user: {
                    displayName: user.displayName,
                    email: user.email,
                    id: user.id,
                },
                payload: {
                    pageName: 'TemporalCustomerPrices',
                },
                correlationId: '',
                eventType: 'ButtonClick',
                applicationId: appConfig.appId,
                clientId: CLIENT_ID,
            });
        },
        onError: error => {
            enqueueSnackbar(error.message, { variant: 'error' });
            onError?.(error);
        },
    });

    const onSubmit = (form: ICustomerPricesMassActionForm) => {
        mutation.mutate({
            state,
            parameters: temporalCustomerPricesFormToApprovalParameterMapper(form),
        });
    };

    return { onSubmit, isLoading: mutation.isLoading };
}

const temporalCustomerPricesFormToApprovalParameterMapper = (
    form: ICustomerPricesMassActionForm
): ITemporalCustomerPricesApprovalMassActionParameters =>
    ({
        Type: form.type as ITemporalCustomerPricesApprovalMassActionParameters['Type'],
        Comment: form.comment,
    } as ITemporalCustomerPricesApprovalMassActionParameters);
