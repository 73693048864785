import { Box } from '@mui/material';
import { IViewTemporalCustomerPricesPage } from 'shared/types';
import { formatDate, formatMoney, formatPercent, formatPmi } from 'shared/utility';

interface CustomerPriceDrawerModelDetailsProps {
    selectedCustomerPrice?: IViewTemporalCustomerPricesPage;
}

export function CustomerPriceDrawerModelDetails({ selectedCustomerPrice }: CustomerPriceDrawerModelDetailsProps) {
    const tableSx = {
        minWidth: '30vw',
    };

    const commonRowSx = {
        borderBottom: '2px solid',
        borderColor: 'grey.400',
        py: 0.5,
        px: 2,
    };

    const headerSx = {
        backgroundColor: 'grey.300',
        fontWeight: 'bold',
        ...commonRowSx,
    };

    const rowSx = {
        display: 'flex',
        justifyContent: 'space-between',
        gap: 4,
        ...commonRowSx,
    };

    return (
        <Box display='flex' justifyContent={'center'} gap={6} pt={4}>
            <Box sx={tableSx}>
                <Box sx={headerSx}>Product & Market Trends</Box>
                <Box sx={rowSx}>
                    <Box>Last Month Variable Cost</Box>
                    <Box>{displayValue(selectedCustomerPrice?.currentCost, formatMoney)}</Box>
                </Box>
                <Box sx={rowSx}>
                    <Box>Variable cost</Box>
                    <Box>{displayValue(selectedCustomerPrice?.updatedCost, formatMoney)}</Box>
                </Box>
                <Box sx={rowSx}>
                    <Box>90-Day CM %</Box>
                    <Box>{displayValue(selectedCustomerPrice?.cmPct90Day, formatPercent)}</Box>
                </Box>
                <Box sx={rowSx}>
                    <Box>Cost Change %</Box>
                    <Box>{displayValue(selectedCustomerPrice?.costChangePct, formatPercent)}</Box>
                </Box>
                <Box sx={rowSx}>
                    <Box>Price Change %</Box>
                    <Box>{displayValue(selectedCustomerPrice?.priceChangePct, formatPercent)}</Box>
                </Box>
                <Box sx={rowSx}>
                    <Box>Last Price Change Date</Box>
                    <Box>{displayValue(selectedCustomerPrice?.lastPriceChangeDate)} </Box>
                </Box>
            </Box>
            <Box sx={tableSx}>
                <Box sx={headerSx}>Other Infomation</Box>
                <Box sx={rowSx}>
                    <Box>Product Specialization</Box>
                    <Box>{selectedCustomerPrice?.productReachCategory}</Box>
                </Box>
                <Box sx={rowSx}>
                    <Box>Elasticity Category</Box>
                    <Box>{selectedCustomerPrice?.elasticityCategory}</Box>
                </Box>
                <Box sx={rowSx}>
                    <Box>Product Tier</Box>
                    <Box>{selectedCustomerPrice?.productTier}</Box>
                </Box>
                <Box sx={rowSx}>
                    <Box>Ag & Food Composite Index</Box>
                    <Box>{displayValue(selectedCustomerPrice?.agFoodCompositeIndex, formatPercent)}</Box>
                </Box>
                <Box sx={rowSx}>
                    <Box>PMI – Purchasing Managers Index</Box>
                    <Box>{displayValue(selectedCustomerPrice?.pmi, formatPmi)}</Box>
                </Box>
                <Box sx={rowSx}>
                    <Box>Purchasing Breadth</Box>
                    <Box>{selectedCustomerPrice?.purchasingBreadthCategory}</Box>
                </Box>
                <Box sx={rowSx}>
                    <Box>Customer Tier</Box>
                    <Box>{selectedCustomerPrice?.customerTier}</Box>
                </Box>
                <Box sx={rowSx}>
                    <Box>Peer Group</Box>
                    <Box>{selectedCustomerPrice?.productPeer}</Box>
                </Box>
            </Box>
        </Box>
    );
}

const displayValue = (
    value: number | String | undefined,
    formatter?: (value: number) => string,
    emptyResult: string = 'Insufficient Data'
) => {
    if (value === null || value === undefined) return emptyResult;
    if (typeof value === 'number' && formatter) {
        return formatter(value);
    } else if (typeof value === 'string') {
        return formatDate(new Date(String(value)));
    }
};
