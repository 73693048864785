import { modifiedByFormatter, useService } from '@insight2profit/drive-app';
import { LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import { DataGridPremium, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { useI2pSimpleDataGrid } from '@price-for-profit/data-grid';
import { NullCell, SHORT_DATE_COLUMN } from 'shared/components/app/DataGridCells';
import { CustomerPricesForeignCurrencyRenderCell } from 'shared/components/app/temporalCustomerPrices/CustomerPricesForeignCurrencyRenderCell';
import { QUERY_KEYS, SP_TEMPORAL_GET_CUSTOMER_PRICE_HISTORY } from 'shared/constants';
import { HEADER_HEIGHT, SLIM_DATA_GRID_STYLE } from 'shared/constants/dataGrid';
import { useExchangeRates } from 'shared/providers';
import {
    ISpTemporalGetCustomerPriceHistory,
    ISpTemporalGetCustomerPriceHistoryParams,
    IViewTemporalCustomerPricesPage,
} from 'shared/types';
import { customerPricesForeignCurrencyValueGetterCurried, longDateValueFormatter } from 'shared/utility';

type TemporalCustomerPriceDrawerPriceChangeHistoryProps = {
    selectedCustomerPrice: IViewTemporalCustomerPricesPage | undefined;
};

export function TemporalCustomerPriceDrawerPriceChangeHistory({
    selectedCustomerPrice,
}: TemporalCustomerPriceDrawerPriceChangeHistoryProps) {
    const { namedQueryService } = useService();
    const { isForeignCurrency, exchangeRates } = useExchangeRates();
    const columns: GridColDef<ISpTemporalGetCustomerPriceHistory>[] = [
        {
            headerName: 'id',
            field: 'id',
            flex: 1,
            hide: true,
            filterable: false,
        },
        {
            headerName: 'Recommended Price',
            field: 'recommendedPriceTimesPerQuantity',
            valueGetter: customerPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
            renderCell: (params: GridRenderCellParams) => {
                return <CustomerPricesForeignCurrencyRenderCell {...params} />;
            },
            width: 180,
        },
        {
            headerName: 'Current price in SAP',
            field: 'currentPriceInSAP',
            valueGetter: customerPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
            renderCell: (params: GridRenderCellParams) => {
                return <CustomerPricesForeignCurrencyRenderCell {...params} />;
            },
            width: 150,
        },
        {
            headerName: 'Revised Price',
            field: 'revisedPrice',
            valueGetter: customerPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
            renderCell: params =>
                params.row.isScalePrice ? (
                    <NullCell {...params} />
                ) : (
                    <CustomerPricesForeignCurrencyRenderCell {...params} />
                ),
            width: 120,
        },
        {
            headerName: 'Document Currency',
            field: 'documentCurrency',
            width: 140,
        },
        {
            headerName: 'Per Quantity',
            field: 'perQuantity',
            width: 120,
        },
        {
            headerName: 'UOM',
            field: 'uom',
            width: 100,
        },
        {
            headerName: 'Valid From',
            field: 'validFrom',
            width: 120,
            ...SHORT_DATE_COLUMN,
        },
        {
            headerName: 'Valid To',
            field: 'validTo',
            width: 120,
            ...SHORT_DATE_COLUMN,
        },
        {
            headerName: 'Status',
            field: 'status',
            width: 120,
        },
        {
            headerName: 'Approver',
            field: 'approver',
            width: 120,
        },
        {
            headerName: 'Edited by',
            field: 'updatedByDisplayName',
            valueFormatter: modifiedByFormatter,
            width: 120,
        },
        {
            headerName: 'Edited Date',
            field: 'editedDate',
            valueFormatter: longDateValueFormatter,
            width: 200,
        },
    ];

    const { getDataGridProps } = useI2pSimpleDataGrid<ISpTemporalGetCustomerPriceHistory>({
        columns: columns,
        name: QUERY_KEYS.getTempooralCustomerPriceHistory,
        additionalKeys: [
            selectedCustomerPrice?.businessLine,
            selectedCustomerPrice?.soldToId,
            selectedCustomerPrice?.shipToId,
            selectedCustomerPrice?.materialId,
        ],
        queryOptions: {
            enabled: Boolean(
                selectedCustomerPrice?.businessLine &&
                    selectedCustomerPrice?.soldToId &&
                    selectedCustomerPrice?.shipToId &&
                    selectedCustomerPrice?.materialId
            ),
        },
        getData: async () => {
            if (!selectedCustomerPrice) return [];

            const namedQueryResponse = await namedQueryService.executeNamedQuery<
                ISpTemporalGetCustomerPriceHistoryParams,
                ISpTemporalGetCustomerPriceHistory
            >({
                queryName: SP_TEMPORAL_GET_CUSTOMER_PRICE_HISTORY,
                parameters: {
                    businessLine: selectedCustomerPrice.businessLine,
                    soldToId: selectedCustomerPrice.soldToId,
                    shipToId: selectedCustomerPrice.shipToId,
                    materialId: selectedCustomerPrice.materialId,
                },
                page: 0,
                pageSize: 1000,
            });

            return namedQueryResponse.data;
        },
        dataGridInitialState: {
            pagination: { pageSize: 10, page: 0 },
            sorting: {
                sortModel: [
                    {
                        field: 'editedDate',
                        sort: 'desc',
                    },
                ],
            },
        },
        rowsPerPageOptions: [10, 20, 50, 100],
    });
    const dataGridProps = getDataGridProps();

    return (
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
            <DataGridPremium
                {...dataGridProps}
                sx={SLIM_DATA_GRID_STYLE}
                disableSelectionOnClick
                components={{
                    LoadingOverlay: LinearProgress,
                }}
                componentsProps={{
                    panel: {
                        sx: {
                            '& .MuiDataGrid-filterFormColumnInput': {
                                width: 'auto',
                            },
                        },
                    },
                }}
                disableVirtualization={false}
                headerHeight={HEADER_HEIGHT}
            />
        </Box>
    );
}
