import { useService } from '@insight2profit/drive-app';
import { CircularProgress } from '@mui/material';
import { GridRenderEditCellParams, GridRowId, useGridApiContext } from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { ObjectTyped } from '@price-for-profit/micro-services';
import { useMaterialUom } from 'shared/hooks';
import { IExchangeRates, useExchangeRates } from 'shared/providers';
import { IUomConversionService } from 'shared/services';
import { ITableMaterialUOMConversion } from 'shared/types';
import { convertProductPricesToForeignCurrencyValue, debounce, priceFromCMPercent } from 'shared/utility';
import { ValidatedRenderEditCell } from './ValidatedRenderEditCell';

type FieldKey =
    | 'regionalRevisedMinimum'
    | 'regionalRevisedTarget'
    | 'regionalRevisedMinimumCMPercent'
    | 'regionalRevisedTargetCMPercent';

export const ProductPricesInterdependentColumnsRenderEditCell = (params: GridRenderEditCellParams) => {
    const { id, field } = params;
    const apiRef = useGridApiContext();
    const { isForeignCurrency, exchangeRates } = useExchangeRates();
    const { uomConversionService } = useService();

    const { materialUomList, isLoading: isMaterialUomLoading } = useMaterialUom(params.row.materialId);

    const handleValueChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = Number(event.target.value);
        if (!newValue || isNaN(newValue)) return;

        setInterdependentCellValues({
            newValue: newValue,
            id,
            field,
            apiRef,
            params,
            isForeignCurrency,
            exchangeRates,
            uomConversionService,
            materialUomList,
        });
    };
    if (isMaterialUomLoading && params.field.includes('CMPercent')) {
        return <CircularProgress size={20} />;
    }
    return <ValidatedRenderEditCell {...params} onValueChange={handleValueChange} />;
};

interface SetInterdependentCellValuesParams {
    newValue: number;
    id: GridRowId;
    field: string;
    apiRef: React.MutableRefObject<GridApiPremium>;
    params: GridRenderEditCellParams;
    isForeignCurrency: boolean;
    exchangeRates: IExchangeRates;
    uomConversionService: IUomConversionService;
    materialUomList: ITableMaterialUOMConversion;
}

const setInterdependentCellValues = debounce(
    async ({
        newValue,
        id,
        field,
        apiRef,
        params,
        isForeignCurrency,
        exchangeRates,
        uomConversionService,
        materialUomList,
    }: SetInterdependentCellValuesParams) => {
        await setCmPercentRelatedFields({
            newValue,
            id,
            field,
            apiRef,
            params,
            isForeignCurrency,
            exchangeRates,
            uomConversionService,
            materialUomList,
        });
    },
    500
);

interface SetCmPercentRelatedFields {
    newValue: number;
    id: GridRowId;
    field: string;
    apiRef: React.MutableRefObject<GridApiPremium>;
    params: GridRenderEditCellParams;
    isForeignCurrency: boolean;
    exchangeRates: IExchangeRates;
    uomConversionService: IUomConversionService;
    materialUomList: ITableMaterialUOMConversion;
}

const setCmPercentRelatedFields = async ({
    newValue,
    id,
    field,
    apiRef,
    params,
    isForeignCurrency,
    exchangeRates,
    uomConversionService,
    materialUomList,
}: SetCmPercentRelatedFields) => {
    const isCmPercentField = cmPercentFields.includes(field as FieldKey);

    if (!isCmPercentField) return;
    if (!params.row.updatedCost) return;

    const cmPercentRelatedFields = cmPercentFieldMap[field as FieldKey];
    if (!cmPercentRelatedFields?.length) return;

    const latestUomDuringEdit = params.row.uom;

    const updatedCostKaiUsd = params.row.updatedCost;

    for (const cmPercentRelatedField of cmPercentRelatedFields) {
        const cmPercentRelatedFieldLatestValueDuringEdit = params.row[cmPercentRelatedField];
        if (!cmPercentRelatedFieldLatestValueDuringEdit) break;

        const currencyCorrectedupdatedCostKai = convertProductPricesToForeignCurrencyValue({
            usdPrice: updatedCostKaiUsd,
            exchangeRates,
            orgRegion: params.row.orgRegion,
            isForeignCurrency,
        });

        const currencyUomCorrectedUpdatedCost = uomConversionService.convertKaiToAnyUom({
            kaiPrice: currencyCorrectedupdatedCostKai,
            uom: latestUomDuringEdit,
            materialKaiKgConversionFactor: params.row.materialKaiKgConversionFactor,
            materialUomList,
        });

        const calculatedValue = priceFromCMPercent({
            updatedCost: currencyUomCorrectedUpdatedCost,
            cmPercent: newValue,
        });

        await apiRef.current.setEditCellValue({
            id,
            field: cmPercentRelatedField,
            value: calculatedValue,
        });
    }
};

const cmPercentFieldMap: Partial<Record<FieldKey, string[]>> = {
    regionalRevisedMinimumCMPercent: ['regionalRevisedMinimum'],
    regionalRevisedTargetCMPercent: ['regionalRevisedTarget'],
};
const cmPercentFields = ObjectTyped.keys(cmPercentFieldMap);
