import { Alert, Button, Stack } from '@mui/material';
import Box from '@mui/system/Box';
import { ServerSideState } from '@price-for-profit/data-grid';
import { difference } from 'lodash';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormAutocomplete, FormDateField, FormNumberField, FormSelect, SaveButton } from 'shared/components/forms';
import { APPROVAL_CHANGE_MASS_ACTIONS, MASS_ACTION_TYPES, TYPE_OPTIONS } from 'shared/constants';
import { useMassActionFormLogic, useUserPermissions } from 'shared/hooks';
import {
    isAmtChangeMassActionCheck,
    isPriceChangeMassActionCheck,
    isScaleChangeMassActionCheck,
    isUomChangeMassActionCheck,
    isValidityDatesChangeMassActionCheck,
    useCustomerPricesMassActionField,
    useCustomerPricesMassActionIsDisabled,
    useCustomerPricesMassActionResetForm,
} from 'shared/hooks/customerPrices';
import {
    useTemporalCustomerPricesApprovalMassActionMutation,
    useTemporalCustomerPricesMassActionMutation,
    useTemporalCustomerPricesMassActionValidationMutation,
    useTemporalCustomerPricesSubmitMassActionMutation,
} from 'shared/mutations';
import { useNonStandardAndStandardUomDropdown } from 'shared/queries/nonStandardAndStandardUomDropdownQuery';
import { ICustomerPricesMassActionForm, ICustomerPricesMassActionParameters } from 'shared/types';
import { formatCommaSeparatedNumber } from 'shared/utility';
import { GridToolbarMassAction, ModalHeader } from '../mass-actions';
import { CustomerPricesApprovalChangeForms } from './CustomerPricesApprovalChangeForms';
import { TemporalCustomerPricesMassActionValidation } from './CustomerPricesMassActionValidation';

type CustomerPricesMassActionModalProps = {
    state: ServerSideState;
    invalidate: () => void;
    rowCount: number;
};

export function TemporalCustomerPricesMassActionModal({
    state,
    invalidate,
    rowCount,
}: CustomerPricesMassActionModalProps) {
    const { enqueueSnackbar } = useSnackbar();
    const [isOpen, setIsOpen] = useState(false);
    const [error, setError] = useState<string>('');
    const { userApproverTier } = useUserPermissions();
    const { data: uomValueOptions } = useNonStandardAndStandardUomDropdown();

    const {
        mutation: massActionValidationMutation,
        onSubmitValidate,
        setValidationRows,
        validationRows,
        isValidated,
        setIsValidated,
        recordsWithoutValidityDate,
        setRecordsWithoutValidityDate,
    } = useTemporalCustomerPricesMassActionValidationMutation({
        customerPricesState: state,
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
        watch,
        resetField,
        reset,
        setValue,
    } = useForm<ICustomerPricesMassActionForm>({
        defaultValues: {
            type: MASS_ACTION_TYPES.AMOUNT,
            field: '',
            value: '',
            comment: '',
        },
    });

    const selectedType = watch('type');
    const selectedField = watch('field');

    const { allowedFieldOptions } = useCustomerPricesMassActionField(selectedType);

    const {
        determineLabel,
        validatePrice,
        determineNumberFieldType,
        validateScaleAndAmount,
        validateValidityDate,
    } = useMassActionFormLogic({
        selectedType,
        selectedField,
    });
    const isDisabled = useCustomerPricesMassActionIsDisabled({ watch });
    useCustomerPricesMassActionResetForm({
        resetField,
        setValue,
        watch,
    });

    const isPriceChangeMassAction = isPriceChangeMassActionCheck(selectedType);
    const isScaleChangeMassAction = isScaleChangeMassActionCheck(selectedType);
    const isAmtChangeMassAction = isAmtChangeMassActionCheck(selectedType);
    const isValidityDatesMassAction = isValidityDatesChangeMassActionCheck(selectedType);
    const isUomMassAction = isUomChangeMassActionCheck(selectedType);
    const isApprovalChangeMassAction = APPROVAL_CHANGE_MASS_ACTIONS.includes(selectedType);
    const isSubmitMassAction = selectedType === MASS_ACTION_TYPES.SUBMIT;

    const {
        onSubmit: onSubmitPriceChangeMassAction,
        isLoading: isPriceChangeMassActionLoading,
    } = useTemporalCustomerPricesMassActionMutation({
        state: state,
        onSuccess: () => {
            handleCloseBothModals();
            setError('');
            invalidate();
            reset();
        },
        onError: error => {
            setError(error.message);
        },
    });

    const {
        onSubmit: onSubmitSubmissionMassAction,
        isLoading: isSubmitMassActionLoading,
    } = useTemporalCustomerPricesSubmitMassActionMutation({
        state: state,
        onSuccess: () => {
            setRecordsWithoutValidityDate(0);
            handleCloseBothModals();
            setError('');
            invalidate();
            reset();
        },
        onError: error => {
            setError(error.message);
        },
    });

    const {
        onSubmit: onSubmitApprovalMassAction,
        isLoading: isApprovalLoading,
    } = useTemporalCustomerPricesApprovalMassActionMutation({
        state: state,
        onSuccess: () => {
            handleCloseBothModals();
            setRecordsWithoutValidityDate(0);
            setError('');
            invalidate();
            reset();
        },
        onError: error => {
            setError(error.message);
        },
    });

    const handleCloseParentModal = () => {
        setIsOpen(false);
        reset();
        setIsValidated(false);
    };

    const handleCloseBothModals = () => {
        handleCloseParentModal();
        handleCloseChildModal();
    };

    const handleCloseChildModal = () => {
        setIsValidated(false);
        reset();
        setValidationRows([]);
    };

    const determineSubmitHandler: (data: ICustomerPricesMassActionForm) => void = data => {
        if (([MASS_ACTION_TYPES.APPROVE, MASS_ACTION_TYPES.REJECT] as string[]).includes(data.type)) {
            onSubmitApprovalMassAction(data);
        } else if (data.type === MASS_ACTION_TYPES.SUBMIT) {
            if (isValidated && validationRows.length === 0 && recordsWithoutValidityDate === 0) {
                onSubmitSubmissionMassAction();
            }
        } else {
            onSubmitPriceChangeMassAction(data);
        }
    };

    const isSubmittingDisabled = selectedType === MASS_ACTION_TYPES.SUBMIT && recordsWithoutValidityDate > 0;

    const allowedTypeOptions =
        userApproverTier >= 1 ? TYPE_OPTIONS : difference(TYPE_OPTIONS, APPROVAL_CHANGE_MASS_ACTIONS);

    return (
        <GridToolbarMassAction isOpen={isOpen} handleOpen={() => setIsOpen(true)} handleClose={handleCloseParentModal}>
            <ModalHeader handleClose={handleCloseParentModal}>Mass Action</ModalHeader>
            <Stack p={2} gap={2} sx={{ minWidth: '400px' }}>
                <FormSelect
                    options={allowedTypeOptions.map(value => ({ value, displayText: value }))}
                    width='100%'
                    label='Price Change Type'
                    name='type'
                    control={control}
                    fieldError={errors['type']}
                    rules={{ required: 'Required' }}
                    isDisabled={isValidated || massActionValidationMutation.isLoading}
                />
                {(isPriceChangeMassAction ||
                    isScaleChangeMassAction ||
                    isAmtChangeMassAction ||
                    isValidityDatesMassAction) && (
                    <FormSelect
                        options={allowedFieldOptions}
                        width='100%'
                        label='Field'
                        name='field'
                        control={control}
                        fieldError={errors['field']}
                        rules={{ required: 'Required' }}
                        isDisabled={isValidated || massActionValidationMutation.isLoading}
                    />
                )}
                {isPriceChangeMassAction && selectedField === 'revisedPrice' && (
                    <FormNumberField
                        name='value'
                        label={determineLabel('Revised Price')}
                        control={control}
                        rules={{
                            validate: validatePrice,
                        }}
                        fieldError={errors['value']}
                        type={determineNumberFieldType()}
                        isDisabled={isValidated || massActionValidationMutation.isLoading}
                    />
                )}
                {isScaleChangeMassAction && selectedField === 'scaleQty01' && (
                    <FormNumberField
                        name='value'
                        label={determineLabel('Scale 1')}
                        control={control}
                        rules={{
                            validate: validateScaleAndAmount,
                        }}
                        fieldError={errors['value']}
                        type={determineNumberFieldType()}
                        isDisabled={isValidated || massActionValidationMutation.isLoading}
                    />
                )}
                {isAmtChangeMassAction && selectedField === 'amount01' && (
                    <FormNumberField
                        name='value'
                        label={determineLabel('Amount 1')}
                        control={control}
                        rules={{
                            validate: validateScaleAndAmount,
                        }}
                        fieldError={errors['value']}
                        type={determineNumberFieldType()}
                        isDisabled={isValidated || massActionValidationMutation.isLoading}
                    />
                )}
                {isScaleChangeMassAction && selectedField === 'scaleQty02' && (
                    <FormNumberField
                        name='value'
                        label={determineLabel('Scale 2')}
                        control={control}
                        rules={{
                            validate: validateScaleAndAmount,
                        }}
                        fieldError={errors['value']}
                        type={determineNumberFieldType()}
                        isDisabled={isValidated || massActionValidationMutation.isLoading}
                    />
                )}
                {isAmtChangeMassAction && selectedField === 'amount02' && (
                    <FormNumberField
                        name='value'
                        label={determineLabel('Amount 2')}
                        control={control}
                        rules={{
                            validate: validateScaleAndAmount,
                        }}
                        fieldError={errors['value']}
                        type={determineNumberFieldType()}
                        isDisabled={isValidated || massActionValidationMutation.isLoading}
                    />
                )}
                {isScaleChangeMassAction && selectedField === 'scaleQty03' && (
                    <FormNumberField
                        name='value'
                        label={determineLabel('Scale 3')}
                        control={control}
                        rules={{
                            validate: validateScaleAndAmount,
                        }}
                        fieldError={errors['value']}
                        type={determineNumberFieldType()}
                        isDisabled={isValidated || massActionValidationMutation.isLoading}
                    />
                )}
                {isAmtChangeMassAction && selectedField === 'amount03' && (
                    <FormNumberField
                        name='value'
                        label={determineLabel('Amount 3')}
                        control={control}
                        rules={{
                            validate: validateScaleAndAmount,
                        }}
                        fieldError={errors['value']}
                        type={determineNumberFieldType()}
                        isDisabled={isValidated || massActionValidationMutation.isLoading}
                    />
                )}
                {isScaleChangeMassAction && selectedField === 'scaleQty04' && (
                    <FormNumberField
                        name='value'
                        label={determineLabel('Scale 4')}
                        control={control}
                        rules={{
                            validate: validateScaleAndAmount,
                        }}
                        fieldError={errors['value']}
                        type={determineNumberFieldType()}
                        isDisabled={isValidated || massActionValidationMutation.isLoading}
                    />
                )}
                {isAmtChangeMassAction && selectedField === 'amount04' && (
                    <FormNumberField
                        name='value'
                        label={determineLabel('Amount 4')}
                        control={control}
                        rules={{
                            validate: validateScaleAndAmount,
                        }}
                        fieldError={errors['value']}
                        type={determineNumberFieldType()}
                        isDisabled={isValidated || massActionValidationMutation.isLoading}
                    />
                )}
                {isScaleChangeMassAction && selectedField === 'scaleQty05' && (
                    <FormNumberField
                        name='value'
                        label={determineLabel('Scale 5')}
                        control={control}
                        rules={{
                            validate: validateScaleAndAmount,
                        }}
                        fieldError={errors['value']}
                        type={determineNumberFieldType()}
                        isDisabled={isValidated || massActionValidationMutation.isLoading}
                    />
                )}
                {isAmtChangeMassAction && selectedField === 'amount05' && (
                    <FormNumberField
                        name='value'
                        label={determineLabel('Amount 5')}
                        control={control}
                        rules={{
                            validate: validateScaleAndAmount,
                        }}
                        fieldError={errors['value']}
                        type={determineNumberFieldType()}
                        isDisabled={isValidated || massActionValidationMutation.isLoading}
                    />
                )}
                {isScaleChangeMassAction && selectedField === 'scaleQty06' && (
                    <FormNumberField
                        name='value'
                        label={determineLabel('Scale 6')}
                        control={control}
                        rules={{
                            validate: validateScaleAndAmount,
                        }}
                        fieldError={errors['value']}
                        type={determineNumberFieldType()}
                        isDisabled={isValidated || massActionValidationMutation.isLoading}
                    />
                )}
                {isAmtChangeMassAction && selectedField === 'amount06' && (
                    <FormNumberField
                        name='value'
                        label={determineLabel('Amount 6')}
                        control={control}
                        rules={{
                            validate: validateScaleAndAmount,
                        }}
                        fieldError={errors['value']}
                        type={determineNumberFieldType()}
                        isDisabled={isValidated || massActionValidationMutation.isLoading}
                    />
                )}
                {isValidityDatesMassAction && (
                    <FormDateField
                        name='value'
                        label='Set Date'
                        control={control}
                        rules={{
                            validate: validateValidityDate,
                        }}
                        fieldError={errors['value']}
                        isDisabled={isValidated || massActionValidationMutation.isLoading}
                    />
                )}
                {isUomMassAction && (
                    <FormAutocomplete
                        options={uomValueOptions.map(uomValueOption => ({
                            value: uomValueOption.uom,
                            displayText: uomValueOption.displayUnit ?? uomValueOption.uom,
                        }))}
                        width='100%'
                        label='Uom'
                        name='value'
                        control={control}
                        rules={{ required: 'Required' }}
                        isDisabled={isValidated || massActionValidationMutation.isLoading}
                    />
                )}
                {isApprovalChangeMassAction && (
                    <CustomerPricesApprovalChangeForms control={control} errors={errors} isLoading={false} />
                )}
                <Box display='flex' justifyContent='space-between'>
                    <Box>Affected Records: </Box>
                    <Box>{formatCommaSeparatedNumber(rowCount)}</Box>
                </Box>
                {isApprovalChangeMassAction && (
                    <Alert severity='info'>Only rows requiring approval will be updated.</Alert>
                )}
                {isSubmitMassAction && <Alert severity='info'>Only unsubmitted rows will be submitted.</Alert>}
                {error && <Alert severity='error'>Save failed. Changes reverted. Please try again.</Alert>}
                {isValidated && validationRows.length === 0 && !error && !isSubmittingDisabled && (
                    <Alert severity='success'>Mass action successfully validated</Alert>
                )}
                {isUomMassAction && (
                    <Alert severity='info'>
                        Only materials with a conversion factor for the selected UOM will be converted
                    </Alert>
                )}
                {isValidated && isSubmittingDisabled && (
                    <Alert severity='error'>
                        {recordsWithoutValidityDate > 1
                            ? `There are ${recordsWithoutValidityDate} rows without validity dates. Please, fix those rows before submitting them.`
                            : 'There is 1 row without validity dates. Please, fix this row before submitting it.'}
                    </Alert>
                )}
                <Box display='flex' justifyContent='space-between'>
                    <Button sx={{ color: 'secondary.main' }} onClick={handleCloseParentModal}>
                        Cancel
                    </Button>
                    {(isPriceChangeMassAction && isValidated && validationRows.length === 0) ||
                    isApprovalChangeMassAction ||
                    isUomMassAction ||
                    (isValidated && validationRows.length === 0) ? (
                        <SaveButton
                            text='Submit'
                            submitHandler={handleSubmit(determineSubmitHandler)}
                            isLoading={isPriceChangeMassActionLoading || isSubmitMassActionLoading || isApprovalLoading}
                            isDisabled={validationRows.length > 0 || isDisabled || isSubmittingDisabled}
                        />
                    ) : (
                        <SaveButton
                            text='Validate'
                            submitHandler={handleSubmit((data: ICustomerPricesMassActionForm) => {
                                enqueueSnackbar('Validating mass action...', { variant: 'info' });
                                onSubmitValidate(data);
                            })}
                            isLoading={massActionValidationMutation.isLoading}
                            isDisabled={isDisabled}
                        />
                    )}
                </Box>
            </Stack>
            <TemporalCustomerPricesMassActionValidation
                isChildModalOpen={!massActionValidationMutation.isLoading && validationRows.length > 0}
                handleCloseChild={handleCloseChildModal}
                validationRows={validationRows}
                selectedType={selectedType as ICustomerPricesMassActionParameters['Type']}
            />
        </GridToolbarMassAction>
    );
}
