import { useService, useUser } from '@insight2profit/drive-app';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, SvgIconTypeMap, Tooltip } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { GridRowParams } from '@mui/x-data-grid-premium';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormTextField, SaveButton } from 'shared/components/forms';
import { productPriceStatuses } from 'shared/constants';
import { useUserPermissions } from 'shared/hooks';
import {
    useProductPriceApproveMutation,
    useProductPriceCommentsMutation,
    useProductPriceRejectMutation,
} from 'shared/mutations';
import { useCustomerProductEngineBatchMetaDatas, usePricesInvalidator } from 'shared/providers';
import { ITableProductPricesComments, ITableProductPricesCommentsForm, IViewProductPrices } from 'shared/types';

type ProductPricesApprovalActionsProps = {
    params: GridRowParams<IViewProductPrices>;
};

const APPROVE = 'Approve';
const REJECT = 'Reject';

type DialogType = typeof APPROVE | typeof REJECT | null;

export function ProductPricesApprovalActions({ params }: ProductPricesApprovalActionsProps) {
    const [dialogType, setDialogType] = useState<DialogType>(null);
    const { isInProgress, isLoading: batchMetadataIsLoading } = useCustomerProductEngineBatchMetaDatas();
    const { userApproverTier } = useUserPermissions();
    const { productPriceApprovalService } = useService();
    const { invalidateQuery } = usePricesInvalidator();
    const user = useUser();

    const {
        handleSubmit,
        control,
        formState: { errors },
        resetField,
        reset,
    } = useForm<ITableProductPricesCommentsForm>({
        defaultValues: {
            comment: '',
        },
    });

    const commentMutation = useProductPriceCommentsMutation({ onSuccess: () => {} });
    const rejectMutation = useProductPriceRejectMutation();
    const approveMutation = useProductPriceApproveMutation();

    const openApproveDialog = () => {
        setDialogType(APPROVE);
    };

    const openRejectDialog = () => {
        setDialogType(REJECT);
    };

    const closeDialog = () => {
        reset();
        setDialogType(null);
    };

    const isOpen = !!dialogType;

    const onSubmit = (form: ITableProductPricesCommentsForm) => {
        if (!form.comment) throw Error('Comment cannot be blank');
        const today = new Date();
        const formattedToday = today.toISOString();
        if (params.row && user) {
            const newCommentPayload: ITableProductPricesComments = {
                id: 0,
                businessLine: params.row.businessLine || '',
                materialId: params.row.materialId || '',
                orgRegion: params.row.orgRegion || '',
                application: params.row.application || '',
                comment: form.comment,
                modifiedBy: user.displayName,
                effectiveStart: formattedToday,
                effectiveEnd: null,
                deleted: false,
            };

            if (dialogType === APPROVE) {
                approveMutation.mutate(params.row);
            }

            if (dialogType === REJECT) {
                rejectMutation.mutate(params.row);
            }

            commentMutation.mutate(newCommentPayload);
            resetField('comment');
            invalidateQuery();
            closeDialog();
        }
    };

    const canApprove = productPriceApprovalService.canCurrentUserApprove({
        currentViewRow: params.row,
        userApproverTier,
    });

    const requiresApproval =
        productPriceStatuses.REGIONAL_APPROVAL_REQUIRED === params.row.status ||
        productPriceStatuses.APPROVAL_REQUIRED === params.row.status;

    const isDisabled =
        isInProgress(params.row.businessLine) ||
        batchMetadataIsLoading ||
        !requiresApproval ||
        !canApprove ||
        !!errors?.['comment'];
    const isLoading = commentMutation.isLoading || rejectMutation.isLoading || approveMutation.isLoading;

    return [
        <TooltipIconButton
            key={`approve-tooltip-btn-${params.row.id}`}
            tooltip='Approve'
            onClick={openApproveDialog}
            Icon={DoneIcon}
            color='green'
            isDisabled={isDisabled}
        />,
        <TooltipIconButton
            key={`reject-tooltip-btn-${params.row.id}`}
            tooltip='Reject'
            onClick={openRejectDialog}
            Icon={CloseIcon}
            color='red'
            isDisabled={isDisabled}
        />,
        <Dialog key={`approval-dialog-${params.row.id}`} onClose={closeDialog} open={isOpen} maxWidth={'sm'} fullWidth>
            <DialogTitle>{dialogType}</DialogTitle>
            <DialogContent>
                <Box sx={{ p: 2 }}>
                    <FormTextField
                        name={'comment'}
                        label={'Comment'}
                        control={control}
                        rules={{ maxLength: 1000, required: 'cannot be blank' }}
                        fieldError={errors['comment']}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>Cancel</Button>
                <SaveButton
                    text={dialogType || ''}
                    submitHandler={handleSubmit(onSubmit)}
                    isLoading={isLoading}
                    isDisabled={!!errors['comment']}
                />
            </DialogActions>
        </Dialog>,
    ];
}

type TooltipIconButtonProps = {
    onClick: () => void;
    Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
        muiName: string;
    };
    tooltip: string;
    isDisabled: boolean;
    color: string;
};

export function TooltipIconButton({ onClick, Icon, tooltip, isDisabled, color }: TooltipIconButtonProps) {
    if (isDisabled) {
        return (
            <Button sx={{ p: 0, m: 0, minWidth: '40px' }} onClick={onClick} disabled={isDisabled}>
                <Icon sx={{ color: 'gray', p: 0, m: 0, borderRadius: '50%' }} />
            </Button>
        );
    }

    return (
        <Tooltip title={tooltip}>
            <Box>
                <Button sx={{ p: 0, m: 0, minWidth: '40px', borderRadius: '50%' }} onClick={onClick}>
                    <Icon sx={{ color, p: 0, m: 0 }} />
                </Button>
            </Box>
        </Tooltip>
    );
}
