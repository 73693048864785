import { MASS_ACTION_TYPES } from 'shared/constants';
import { useMassActionField } from '../massActions';
import { useUserPermissions } from '../use-user-permissions';

export function useProductPricesMassActionField(selectedType: string) {
    const permissions = useUserPermissions();

    const isCMPercentTypeSelected = [
        MASS_ACTION_TYPES.AMOUNT as string,
        MASS_ACTION_TYPES.INCREASE_PERCENT as string,
    ].includes(selectedType);

    const isPriceTypeSelected = [
        MASS_ACTION_TYPES.AMOUNT as string,
        MASS_ACTION_TYPES.CHANGE_DOLLAR as string,
        MASS_ACTION_TYPES.CHANGE_PERCENT as string,
    ].includes(selectedType);

    const fieldOptions = [
        {
            value: 'regionalRevisedMinimum',
            displayText: 'Revised Minimum Price',
            isAllowed: permissions.data.isRegionalRevisedMinimumWriteAllowed && isPriceTypeSelected,
        },
        {
            value: 'regionalRevisedTarget',
            displayText: 'Revised Target Price',
            isAllowed: permissions.data.isRegionalRevisedTargetWriteAllowed && isPriceTypeSelected,
        },
        {
            value: 'regionalRevisedMinimumCMPercent',
            displayText: 'Revised Minimum Adjusted Margin %',
            isAllowed: permissions.data.isRegionalRevisedMinimumWriteAllowed && isCMPercentTypeSelected,
        },
        {
            value: 'regionalRevisedTargetCMPercent',
            displayText: 'Revised Target Adjusted Margin %',
            isAllowed: permissions.data.isRegionalRevisedTargetWriteAllowed && isCMPercentTypeSelected,
        },
    ];

    return useMassActionField(fieldOptions);
}
