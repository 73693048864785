import { useUser } from '@insight2profit/drive-app';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import { FormTextField, SaveButton } from 'shared/components/forms';
import { useCustomerPriceCommentsMutation } from 'shared/mutations';

import {
    ITableCustomerPricesComments,
    ITableCustomerPricesCommentsForm,
    IViewTemporalCustomerPricesPage,
} from 'shared/types';

interface CustomerPriceDrawerAddCommentProps {
    selectedCustomerPrice?: IViewTemporalCustomerPricesPage;
    onSuccess: () => void;
}

export function CustomerPriceDrawerAddCommentForm({
    selectedCustomerPrice,
    onSuccess,
}: CustomerPriceDrawerAddCommentProps) {
    const user = useUser();
    const {
        handleSubmit,
        control,
        formState: { errors },
        resetField,
    } = useForm<ITableCustomerPricesCommentsForm>({
        defaultValues: {
            comment: '',
        },
    });
    const mutation = useCustomerPriceCommentsMutation({ onSuccess });
    const onSubmit = (form: ITableCustomerPricesCommentsForm) => {
        const today = new Date();
        const formattedToday = today.toISOString();
        if (selectedCustomerPrice && user) {
            const newCommentPayload: ITableCustomerPricesComments = {
                id: 0,
                businessLine: selectedCustomerPrice.businessLine || '',
                materialId: selectedCustomerPrice.materialId || '',
                orgRegion: selectedCustomerPrice.orgRegion || '',
                shipToId: selectedCustomerPrice.shipToId,
                soldToId: selectedCustomerPrice.soldToId,
                comment: form.comment,
                modifiedBy: user.displayName,
                effectiveStart: formattedToday,
                effectiveEnd: null,
                deleted: false,
            };
            mutation.mutate(newCommentPayload);
            resetField('comment');
        }
    };

    return (
        <Box display='flex' gap={2} alignItems='center' px={2}>
            <FormTextField
                name={'comment'}
                label={'Comment'}
                control={control}
                rules={{ maxLength: 1000, required: 'cannot be blank' }}
                fieldError={errors['comment']}
            />

            <Box width='200px'>
                <SaveButton
                    text='Add Comment'
                    submitHandler={handleSubmit(onSubmit)}
                    isLoading={mutation.isLoading}
                    isDisabled={!!errors['comment']}
                />
            </Box>
        </Box>
    );
}
