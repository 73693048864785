import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { currencyCodeMapper, useExchangeRates } from 'shared/providers';

export const ProductPricesForeignCurrencyRenderCell = (params: GridRenderCellParams) => {
    const { isForeignCurrency } = useExchangeRates();
    const currencyCode = currencyCodeMapper(params.row.orgRegion);
    const valueToFormat = params?.value ?? 0;

    const formattedValue = new Intl.NumberFormat('en-EN', {
        style: 'currency',
        currency: isForeignCurrency ? currencyCode : 'USD',
    }).format(valueToFormat);
    const isEditableCell = params.colDef.editable && params.isEditable ? true : false;

    return <div className={isEditableCell ? 'editable-cell' : ''}>{formattedValue}</div>;
};
