import { UseFormWatch } from 'react-hook-form';
import { IProductPricesMassActionForm } from 'shared/types';

export function useProductPricesMassActionIsDisabled({
    watch,
    selectedType,
    selectedField,
}: {
    watch: UseFormWatch<IProductPricesMassActionForm>;
    selectedType: string;
    selectedField: string;
}) {
    const regionalRevisedMinimum = watch('regionalRevisedMinimum');
    const regionalRevisedTarget = watch('regionalRevisedTarget');
    const regionalRevisedMinimumCMPercent = watch('regionalRevisedMinimumCMPercent');
    const regionalRevisedTargetCMPercent = watch('regionalRevisedTargetCMPercent');

    const isDisabled =
        !selectedType ||
        !selectedField ||
        (selectedField === 'regionalRevisedMinimum' && !regionalRevisedMinimum) ||
        (selectedField === 'regionalRevisedTarget' && !regionalRevisedTarget) ||
        (selectedField === 'regionalRevisedMinimumCMPercent' && !regionalRevisedMinimumCMPercent) ||
        (selectedField === 'regionalRevisedTargetCMPercent' && !regionalRevisedTargetCMPercent);
    return isDisabled;
}
