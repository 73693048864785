import { Box } from '@mui/system';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import { ReactElement } from 'react';
import { useExchangeRates } from 'shared/providers';
import { IViewTemporalCustomerPricesPage } from 'shared/types';

export function DocumentCurrencyCell(
    params: GridRenderCellParams<string, IViewTemporalCustomerPricesPage, string>
): ReactElement {
    const { isForeignCurrency } = useExchangeRates();
    const apiRef = useGridApiContext();
    if (params?.row?.id) {
        const rowMode = apiRef.current.getCellMode(params?.row?.id, 'documentCurrency');
        if (rowMode === 'edit') {
            apiRef.current.stopRowEditMode({
                id: params?.row?.id,
                ignoreModifications: true,
            });
        }
    }

    const cellClassName = isForeignCurrency ? '' : 'editable-cell';

    return <Box className={cellClassName}>{params.row.documentCurrency}</Box>;
}
