import { PRODUCT_PRICES_APPROVER, productPriceStatuses } from 'shared/constants';
import { ITableProductPrices, IViewProductPrices } from 'shared/types';

type GetNewStatusParams = {
    oldTableRow: ITableProductPrices;
    userApproverTier: number;
};

type CanCurrentUserApproveParams = {
    currentViewRow: IViewProductPrices;
    userApproverTier: number;
};

export interface IProductPriceApprovalService {
    getSubmitStatus({
        oldTableRow,
        userApproverTier,
    }: GetNewStatusParams): {
        isAutoApproved: boolean;
        approver: string;
        status: string;
    };
    canCurrentUserApprove({ currentViewRow, userApproverTier }: CanCurrentUserApproveParams): boolean;
    determineStatusChange(changeType: string, status: string): string;
    getStatusFromEdit(
        oldRow: ITableProductPrices | IViewProductPrices,
        newRow: ITableProductPrices | IViewProductPrices
    ): string;
}

export class ProductPriceApprovalService implements IProductPriceApprovalService {
    private changeTypes = {
        APPROVED: 'Approved',
        REJECTED: 'Rejected',
        EDIT: 'Edit',
    };

    getSubmitStatus({ oldTableRow, userApproverTier }: GetNewStatusParams) {
        const tier = this.determineTier({ row: oldTableRow });
        const approver = this.determineApprover(tier);
        const isAutoApproved = this.isAutoApproved(tier, userApproverTier);
        const status = this.determineStatusDisplay(isAutoApproved);

        return { isAutoApproved, approver, status };
    }

    canCurrentUserApprove({ currentViewRow, userApproverTier }: CanCurrentUserApproveParams) {
        try {
            const tier = this.determineTier({ row: currentViewRow });
            const updatedTier = userApproverTier >= tier ? 0 : tier;

            return updatedTier === 0;
        } catch (e) {}
        return false;
    }

    determineStatusChange(changeType: string, status: string = '') {
        if (changeType === this.changeTypes.APPROVED) {
            if (
                status === productPriceStatuses.REGIONAL_APPROVAL_REQUIRED ||
                status === productPriceStatuses.APPROVAL_REQUIRED
            ) {
                return productPriceStatuses.APPROVED;
            }
        }

        if (changeType === this.changeTypes.REJECTED) {
            if (
                status === productPriceStatuses.REGIONAL_APPROVAL_REQUIRED ||
                status === productPriceStatuses.APPROVAL_REQUIRED
            ) {
                return productPriceStatuses.REJECTED;
            }
        }

        if (changeType === this.changeTypes.EDIT) {
            return productPriceStatuses.NEEDS_REVIEW;
        }

        return status;
    }

    getStatusFromEdit(
        oldRow: ITableProductPrices | IViewProductPrices,
        newRow: ITableProductPrices | IViewProductPrices
    ) {
        if (
            oldRow.regionalRevisedMinimum !== newRow.regionalRevisedMinimum ||
            oldRow.regionalRevisedMinimumCMPercent !== newRow.regionalRevisedMinimumCMPercent ||
            oldRow.regionalRevisedTarget !== newRow.regionalRevisedTarget ||
            oldRow.regionalRevisedTargetCMPercent !== newRow.regionalRevisedTargetCMPercent
        ) {
            return this.determineStatusChange(this.changeTypes.EDIT);
        }

        return oldRow.status || 'Error getting status from edit';
    }

    private determineTier({ row }: { row: ITableProductPrices | IViewProductPrices }): number {
        let tier = 0;

        if (row.regionalRevisedMinimum === undefined || row.regionalRevisedMinimum === null)
            throw Error('Error determining required approval level. Missing Revised Minimum.');
        if (row.regionalRevisedTarget === undefined || row.regionalRevisedTarget === null)
            throw Error('Error determining required approval level. Missing Revised Target.');

        const updatedFloorPrice = row.updatedFloorPrice ?? 0;
        if (Number(row.regionalRevisedMinimum.toFixed(2)) < Number(updatedFloorPrice.toFixed(2)))
            tier = Math.max(tier, 4);
        if (Number(row.regionalRevisedTarget.toFixed(2)) < Number(updatedFloorPrice.toFixed(2)))
            tier = Math.max(tier, 4);

        return tier;
    }

    private isAutoApproved(requiredTier: number, userApproverTier: number): boolean {
        return requiredTier <= userApproverTier;
    }

    private determineApprover(tier: number): string {
        if (tier === 0) return PRODUCT_PRICES_APPROVER.NONE;
        if (tier === 1) return PRODUCT_PRICES_APPROVER.REGIONAL_SALES;
        if (tier === 2) return PRODUCT_PRICES_APPROVER.MARKETING_MANAGER;
        if (tier === 4) return PRODUCT_PRICES_APPROVER.PRODUCT_LINE_MANAGEMENT;
        return 'Error getting tier';
    }

    private determineStatusDisplay(isAutoApproved: boolean) {
        if (isAutoApproved) {
            return productPriceStatuses.APPROVED;
        }

        return productPriceStatuses.APPROVAL_REQUIRED;
    }
}
