export const PERMISSIONS = {
    login: 'nouryon.login',
    regionalRevisedMinimum: {
        read: 'nouryon.regional-revised-minimum.read',
        write: 'nouryon.regional-revised-minimum.write',
    },
    regionalRevisedTarget: {
        read: 'nouryon.regional-revised-target.read',
        write: 'nouryon.regional-revised-target.write',
    },
    revisedCustomerPrice: {
        read: 'nouryon.revised-customer-price.read',
        write: 'nouryon.revised-customer-price.write',
    },
    customerExceptions: {
        write: 'nouryon.customer-exceptions.write',
    },
    page: {
        customerPrices: 'nouryon.customer-prices.page',
        productPrices: 'nouryon.product-prices.page',
    },
    preference: {
        showCMPercent: 'nouryon.show-cm-percent.preference',
        showPrice: 'nouryon.show-price.preference',
    },
    productPricesAddRecord: {
        add: 'nouryon.productprices.add',
    },
    customerPricesAddRecord: {
        add: 'nouryon.customerprices.add',
    },
    region: {
        pfEmea: 'nouryon.pf-emea.region',
        pfEurope: 'nouryon.pf-europe.region',
        pfMea: 'nouryon.pf-mea.region',
        pfIndia: 'nouryon.pf-india.region',
        pfNeAsia: 'nouryon.pf-ne-asia.region',
        pfNorthAmerica: 'nouryon.pf-north-america.region',
        pfSeAsia: 'nouryon.pf-se-asia.region',
        pfSouthAmerica: 'nouryon.pf-south-america.region',
        tsAsiaPacific: 'nouryon.ts-asia-pacific.region',
        tsEmea: 'nouryon.ts-emea.region',
        tsNorthAmerica: 'nouryon.ts-north-america.region',
        tsSouthAmerica: 'nouryon.ts-south-america.region',
    },
    marketRegionType: {
        inclusive: 'nouryon.inclusive.market-region-type',
        exclusive: 'nouryon.exclusive.market-region-type',
    },
    marketSegment: {
        notAssigned: 'nouryon.not-assigned.market-segment',
        tsCatalyst: 'nouryon.ts-catalyst.market-segment',
        tsCoatings: 'nouryon.ts-coatings.market-segment',
        tsConstruction: 'nouryon.ts-construction.market-segment',
        tsElectronics: 'nouryon.ts-electronics.market-segment',
        tsFoundry: 'nouryon.ts-foundry.market-segment',
        tsHpmo: 'nouryon.ts-hpmo.market-segment',
        tsOilWellService: 'nouryon.ts-oil-well-service.market-segment',
        tsOther: 'nouryon.ts-other.market-segment',
        tsPackaging: 'nouryon.ts-packaging.market-segment',
        tsPharma: 'nouryon.ts-pharma.market-segment',
        tsPorousCeramics: 'nouryon.ts-porous-ceramics.market-segment',
    },
    approverTier: {
        one: 'nouryon.1.tier',
        two: 'nouryon.2.tier',
        four: 'nouryon.4.tier',
    },
    businessLine: {
        agAndFood: 'nouryon.a-and-f.business-line',
        inorganicSpecialties: 'nouryon.i-s.business-line',
        paintsAndCoatings: 'nouryon.p-and-c.business-line',
        naturalResources: 'nouryon.n-r.business-line',
    },
    customerPricesLevel: {
        region: 'nouryon.region.customer-prices-level',
        row: 'nouryon.row.customer-prices-level',
    },
    model: {
        customerProductEngineAgAndFood: 'nouryon.af-cpe.model',
        customerProductEngineInorganicSpecialties: 'nouryon.is-cpe.model',
        customerProductEnginePaintsAndCoatings: 'nouryon.pc-cpe.model',
        customerProductEngineNaturalResources: 'nouryon.nr-cpe.model',
    },
    featureFlags: {
        cpExceptions: 'nouryon.cp-exceptions.view',
        cpQuote: 'nouryon.cp-quote.view',
    },
} as const;
