import { CLIENT_ID } from '@insight2profit/drive-app';
import { DataAccessResponse } from '@price-for-profit/micro-services';
import { DATABASE_LABEL, TABLE_CUSTOMER_PRICES_PERSISTED_ATTRIBUTES } from 'shared/constants';
import { ITableCustomerPricesPersistedAttributes, IViewTemporalCustomerPricesPage } from 'shared/types';
import { IExchangeRatesService } from './exchangeRatesService';
import { IRowLevelSecurityDasService } from './rowLevelSecurityDasService';

type SoftEditPersistedAttributesParams = {
    newViewRow: IViewTemporalCustomerPricesPage;
    oldViewRow: IViewTemporalCustomerPricesPage;
    userDisplayName?: string;
    userEmail: string;
    isForeignCurrency?: boolean;
};

type AddParams = {
    businessLine: ITableCustomerPricesPersistedAttributes['businessLine'];
    soldToId: ITableCustomerPricesPersistedAttributes['soldToId'];
    shipToId: ITableCustomerPricesPersistedAttributes['shipToId'];
    materialId: ITableCustomerPricesPersistedAttributes['materialId'];
    documentCurrency: ITableCustomerPricesPersistedAttributes['documentCurrency'];
    priceTypeAttributes: ITableCustomerPricesPersistedAttributes['priceTypeAttributes'];
    modifiedBy: ITableCustomerPricesPersistedAttributes['modifiedBy'];
    effectiveStart?: ITableCustomerPricesPersistedAttributes['effectiveStart'];
};

export interface ICustomerPricesPersistedAttributesService {
    softEditPersistedAttributes({
        isForeignCurrency,
        newViewRow,
        oldViewRow,
        userDisplayName,
    }: SoftEditPersistedAttributesParams): Promise<{
        editResponse?: DataAccessResponse<ITableCustomerPricesPersistedAttributes>;
        addResponse: DataAccessResponse<ITableCustomerPricesPersistedAttributes>;
        newViewRow: IViewTemporalCustomerPricesPage;
    }>;
    addNewRow({
        businessLine,
        soldToId,
        shipToId,
        materialId,
        documentCurrency,
        priceTypeAttributes,
        modifiedBy,
        effectiveStart,
    }: AddParams): Promise<DataAccessResponse<ITableCustomerPricesPersistedAttributes>>;
}

export class CustomerPricesPersistedAttributesService implements ICustomerPricesPersistedAttributesService {
    constructor(
        private rlsDasService: IRowLevelSecurityDasService,
        private exchangeRatesService: IExchangeRatesService
    ) {}

    async addNewRow({
        businessLine,
        soldToId,
        shipToId,
        materialId,
        documentCurrency,
        priceTypeAttributes,
        modifiedBy,
        effectiveStart,
    }: AddParams): Promise<DataAccessResponse<ITableCustomerPricesPersistedAttributes>> {
        return await this.rlsDasService.addRow<ITableCustomerPricesPersistedAttributes, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: TABLE_CUSTOMER_PRICES_PERSISTED_ATTRIBUTES,
            payload: {
                customerPricesPersistedAttributesId: 0,
                businessLine,
                soldToId,
                shipToId,
                materialId,
                documentCurrency,
                priceTypeAttributes,
                modifiedBy: modifiedBy,
                effectiveStart: effectiveStart || new Date().toISOString(),
                effectiveEnd: undefined,
                deleted: false,
            },
        });
    }

    async softEditPersistedAttributes({
        isForeignCurrency,
        newViewRow,
        oldViewRow,
        userDisplayName,
    }: SoftEditPersistedAttributesParams): Promise<{
        editResponse?: DataAccessResponse<ITableCustomerPricesPersistedAttributes>;
        addResponse: DataAccessResponse<ITableCustomerPricesPersistedAttributes>;
        newViewRow: IViewTemporalCustomerPricesPage;
    }> {
        if (!newViewRow.uom) throw Error('New UOM cannot be blank');

        const now = new Date().toISOString();

        let editResponse: DataAccessResponse<ITableCustomerPricesPersistedAttributes> | undefined = undefined;

        if (oldViewRow.customerPricesPersistedAttributesId) {
            const currentDocumentCurrencyCode = newViewRow.documentCurrency;
            const exchangeRatesResponse = await this.exchangeRatesService.getExchangeRates();
            const matchingExchangeRate = exchangeRatesResponse?.data?.find(anExchangeRate => {
                return anExchangeRate.fromCurrencyCode === currentDocumentCurrencyCode;
            });
            if (!matchingExchangeRate) {
                throw new Error(`A matching exchange rate was not found for ${currentDocumentCurrencyCode}`);
            }

            editResponse = await this.rlsDasService.patchRow<
                ITableCustomerPricesPersistedAttributes,
                typeof DATABASE_LABEL
            >({
                clientId: CLIENT_ID,
                databaseLabel: DATABASE_LABEL,
                tableId: TABLE_CUSTOMER_PRICES_PERSISTED_ATTRIBUTES,
                key: oldViewRow.customerPricesPersistedAttributesId.toString(),
                operations: [{ op: 'replace', path: '/effectiveEnd', value: now }],
            });
        }

        const modifiedNewTableRow = await this.createNewRow({
            isForeignCurrency,
            newViewRow,
            oldTableRow: oldViewRow,
            userDisplayName: userDisplayName || '',
            now,
        });

        const addResponse = await this.rlsDasService.addRow<
            ITableCustomerPricesPersistedAttributes,
            typeof DATABASE_LABEL
        >({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: TABLE_CUSTOMER_PRICES_PERSISTED_ATTRIBUTES,
            payload: modifiedNewTableRow,
        });

        return { editResponse, addResponse, newViewRow };
    }

    private mapViewCustomerPricesToTableCustomerPricesPersistedAttributes({
        oldViewRow,
        newViewRow,
    }: {
        oldViewRow: IViewTemporalCustomerPricesPage;
        newViewRow: IViewTemporalCustomerPricesPage;
        modifiedBy: string;
        effectiveEnd?: string;
    }): Omit<ITableCustomerPricesPersistedAttributes, 'modifiedBy' | 'deleted' | 'effectiveEnd' | 'effectiveStart'> {
        return {
            customerPricesPersistedAttributesId: oldViewRow.customerPricesPersistedAttributesId as number,
            businessLine: oldViewRow.businessLine,
            soldToId: oldViewRow.soldToId,
            shipToId: oldViewRow.shipToId,
            materialId: oldViewRow.materialId,
            documentCurrency: newViewRow.documentCurrency,
            priceTypeAttributes: newViewRow.priceTypeAttributes,
        };
    }

    private async createNewRow({
        isForeignCurrency,
        newViewRow,
        oldTableRow,
        userDisplayName,
        now,
    }: {
        isForeignCurrency?: boolean;
        newViewRow: IViewTemporalCustomerPricesPage;
        oldTableRow: IViewTemporalCustomerPricesPage;
        userDisplayName: string;
        now: string;
    }) {
        const newTableRowWithoutMetaFields = this.mapViewCustomerPricesToTableCustomerPricesPersistedAttributes({
            oldViewRow: oldTableRow,
            newViewRow: newViewRow,
            modifiedBy: userDisplayName,
        });
        if (isForeignCurrency) {
            const currentDocumentCurrencyCode = newViewRow.documentCurrency;
            const exchangeRatesResponse = await this.exchangeRatesService.getExchangeRates();

            const matchingExchangeRate = exchangeRatesResponse?.data?.find(anExchangeRate => {
                return anExchangeRate.fromCurrencyCode === currentDocumentCurrencyCode;
            });
            if (!matchingExchangeRate) {
                throw new Error(`A matching exchange rate was not found for ${currentDocumentCurrencyCode}`);
            }
        }

        const modifiedNewTableRow: ITableCustomerPricesPersistedAttributes = {
            ...newTableRowWithoutMetaFields,
            customerPricesPersistedAttributesId: 0,
            effectiveStart: now,
            modifiedBy: userDisplayName,
            deleted: false,
            effectiveEnd: undefined,
        };

        return modifiedNewTableRow;
    }
}
