import { getAppPermissions, useUser } from '@insight2profit/drive-app';
import { useCallback, useMemo } from 'react';
import { PERMISSIONS } from 'shared/constants';
import { PermittedRowLevels } from 'shared/services';
import { AppPermission, CustomerPricesLevelPermissionType } from 'shared/types';
import { useBusinessLines } from './use-business-lines';
import { useMarketSegments } from './use-market-segments';
import { useRegions } from './use-regions';

export function usePermissions(claims = '') {
    const user = useUser();
    const permissions: AppPermission[] = useMemo(() => getAppPermissions(claims), [claims]);
    const hasPermission: (appPermission: AppPermission) => boolean = useCallback(
        (appPermission: AppPermission) => {
            return permissions.includes(appPermission);
        },
        [permissions]
    );
    const isViewProductPricesPageAllowed = useMemo(() => hasPermission(PERMISSIONS.page.productPrices), [
        hasPermission,
    ]);
    const isViewCustomerPricesPageAllowed = useMemo(() => hasPermission(PERMISSIONS.page.customerPrices), [
        hasPermission,
    ]);

    const isRegionalRevisedMinimumReadAllowed = useMemo(() => hasPermission(PERMISSIONS.regionalRevisedMinimum.read), [
        hasPermission,
    ]);
    const isRegionalRevisedMinimumWriteAllowed = useMemo(
        () => hasPermission(PERMISSIONS.regionalRevisedMinimum.write),
        [hasPermission]
    );

    const isCustomerExceptionsWriteAllowed = useMemo(() => hasPermission(PERMISSIONS.customerExceptions.write), [
        hasPermission,
    ]);

    const isRegionalRevisedTargetReadAllowed = useMemo(() => hasPermission(PERMISSIONS.regionalRevisedTarget.read), [
        hasPermission,
    ]);
    const isRegionalRevisedTargetWriteAllowed = useMemo(() => hasPermission(PERMISSIONS.regionalRevisedTarget.write), [
        hasPermission,
    ]);

    const isRevisedCustomerPriceReadAllowed = useMemo(() => hasPermission(PERMISSIONS.revisedCustomerPrice.read), [
        hasPermission,
    ]);
    const isRevisedCustomerPriceWriteAllowed = useMemo(() => hasPermission(PERMISSIONS.revisedCustomerPrice.write), [
        hasPermission,
    ]);

    const isShowPricePreferred = useMemo(() => hasPermission(PERMISSIONS.preference.showPrice), [hasPermission]);
    const isShowCMPercentPreferred = useMemo(() => hasPermission(PERMISSIONS.preference.showCMPercent), [
        hasPermission,
    ]);

    const isAddProductPriceAllowed = useMemo(() => hasPermission(PERMISSIONS.productPricesAddRecord.add), [
        hasPermission,
    ]);

    const isAddCustomerPriceAllowed = useMemo(() => hasPermission(PERMISSIONS.customerPricesAddRecord.add), [
        hasPermission,
    ]);

    const isTriggerAfCpeAllowed = useMemo(() => hasPermission(PERMISSIONS.model.customerProductEngineAgAndFood), [
        hasPermission,
    ]);
    const isTriggerIsCpeAllowed = useMemo(
        () => hasPermission(PERMISSIONS.model.customerProductEngineInorganicSpecialties),
        [hasPermission]
    );
    const isTriggerPcCpeAllowed = useMemo(
        () => hasPermission(PERMISSIONS.model.customerProductEnginePaintsAndCoatings),
        [hasPermission]
    );
    const isTriggerNrCpeAllowed = useMemo(
        () => hasPermission(PERMISSIONS.model.customerProductEngineNaturalResources),
        [hasPermission]
    );

    const isViewCpExceptionsAllowed = useMemo(() => hasPermission(PERMISSIONS.featureFlags.cpExceptions), [
        hasPermission,
    ]);
    const isViewCpQuoteAllowed = useMemo(() => hasPermission(PERMISSIONS.featureFlags.cpQuote), [hasPermission]);

    const customerPricesLevel: CustomerPricesLevelPermissionType = useMemo(() => {
        if (hasPermission(PERMISSIONS.customerPricesLevel.region)) return 'region';
        if (hasPermission(PERMISSIONS.customerPricesLevel.row)) return 'row';
        return 'row';
    }, [hasPermission]);

    const isMarketRegionTypeExclusive = useMemo(() => hasPermission(PERMISSIONS.marketRegionType.exclusive), [
        hasPermission,
    ]);
    const isMarketRegionTypeInclusive = useMemo(() => hasPermission(PERMISSIONS.marketRegionType.inclusive), [
        hasPermission,
    ]);

    const { permittedRegions, ...regions } = useRegions({ hasPermission });
    const { permittedBusinessLines, ...businessLines } = useBusinessLines({ hasPermission });
    const { permittedMarketSegments, ...marketSegments } = useMarketSegments({ hasPermission });

    const userApproverTier = useMemo(() => {
        if (hasPermission(PERMISSIONS.approverTier.four)) return 4;
        if (hasPermission(PERMISSIONS.approverTier.two)) return 2;
        if (hasPermission(PERMISSIONS.approverTier.one)) return 1;
        return 0;
    }, [hasPermission]);

    const marketRegionType = (() => {
        if (isMarketRegionTypeInclusive) return 'inclusive' as const;
        if (isMarketRegionTypeExclusive) return 'exclusive' as const;
        return 'ignore' as const;
    })();

    const permittedRowLevels: PermittedRowLevels = useMemo(() => {
        return {
            page: 'productPrices',
            marketRegionType,
            customerPricesLevel,
            permitted: {
                orgRegions: permittedRegions,
                businessLines: permittedBusinessLines,
                marketSegments: permittedMarketSegments,
                rlsNames: [user?.displayName || ''],
            },
        };
    }, [
        customerPricesLevel,
        permittedRegions,
        permittedBusinessLines,
        user?.displayName,
        marketRegionType,
        permittedMarketSegments,
    ]);

    const customerPricesPermittedRowLevels: PermittedRowLevels = useMemo(() => {
        return {
            page: 'customerPrices',
            marketRegionType,
            customerPricesLevel,
            permitted: {
                orgRegions: permittedRegions,
                businessLines: permittedBusinessLines,
                marketSegments: permittedMarketSegments,
                rlsNames: [user?.displayName || ''],
            },
        };
    }, [
        customerPricesLevel,
        permittedRegions,
        permittedBusinessLines,
        user?.displayName,
        marketRegionType,
        permittedMarketSegments,
    ]);

    return {
        data: {
            isAddCustomerPriceAllowed,
            isAddProductPriceAllowed,
            isRegionalRevisedMinimumReadAllowed,
            isRegionalRevisedMinimumWriteAllowed,
            isRegionalRevisedTargetReadAllowed,
            isRegionalRevisedTargetWriteAllowed,
            isRevisedCustomerPriceReadAllowed,
            isRevisedCustomerPriceWriteAllowed,
            isCustomerExceptionsWriteAllowed,
        },
        page: {
            isViewProductPricesPageAllowed,
            isViewCustomerPricesPageAllowed,
        },
        preferences: {
            isShowPricePreferred,
            isShowCMPercentPreferred,
        },
        regions,
        businessLines,
        marketSegments,
        permittedRowLevels,
        customerPricesPermittedRowLevels,
        userApproverTier,
        customerPricesLevel,
        model: {
            isTriggerAfCpeAllowed,
            isTriggerIsCpeAllowed,
            isTriggerPcCpeAllowed,
            isTriggerNrCpeAllowed,
        },
        featureFlags: {
            isViewCpExceptionsAllowed,
            isViewCpQuoteAllowed,
        },
        marketRegionType: {
            isMarketRegionTypeInclusive,
            isMarketRegionTypeExclusive,
        },
    };
}
