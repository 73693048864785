import { AppBar, Button, Dialog, DialogContent, Slide, Toolbar, Typography } from '@mui/material';
import { ServerSideState } from '@price-for-profit/data-grid';
import { useSelectedTemporalCustomerPrice } from 'shared/providers/provideSelectedTemporalCustomerPrice';
import {
    useCompanyAddressesDropdown,
    useCompanyNamesDropdown,
    useTemporalCustomerPricesQuoteQuery,
} from 'shared/queries';
import { IViewTemporalCustomerPricesPage } from 'shared/types';
import { TemporalCustomerPricesQuoteForm } from './TemporalCustomerPricesQuoteForm';
import { forwardRef } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { CloseOutlined } from '@mui/icons-material';
type TemporalCustomerPricesQuoteModalProps = {
    state: ServerSideState;
    rows: IViewTemporalCustomerPricesPage[];
};

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<unknown>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction='up' ref={ref} {...props} />;
});

export function TemporalCustomerPricesQuoteModal({ state }: TemporalCustomerPricesQuoteModalProps) {
    const { close, isQuoteModalOpen } = useSelectedTemporalCustomerPrice();
    const { data: allRows, isLoading } = useTemporalCustomerPricesQuoteQuery({
        state,
        enabled: isQuoteModalOpen,
    });

    const { data: companyNames, isFetching: isFetchingCompanyNames } = useCompanyNamesDropdown();

    const { data: companyAddresses, isFetching: isFetchingCompanyAddresses } = useCompanyAddressesDropdown();

    return (
        <Dialog onClose={close} open={isQuoteModalOpen} fullScreen TransitionComponent={Transition}>
            <AppBar
                sx={{
                    position: 'relative',
                    borderBottom: theme => `1px solid ${theme.palette.divider}`,
                    backgroundColor: theme => theme.palette.grey[300],
                    boxShadow: 'none',
                    color: theme => theme.palette.text.primary,
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{
                            flex: 1,
                        }}
                        variant='h5'
                        component='div'
                        fontWeight={700}
                    >
                        Quote
                    </Typography>
                    <Button autoFocus color='inherit' onClick={close}>
                        <CloseOutlined />
                    </Button>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <TemporalCustomerPricesQuoteForm
                    key={JSON.stringify(state)}
                    isFetchingCompanyAddresses={isFetchingCompanyAddresses}
                    companyAddresses={companyAddresses}
                    isFetchingCompanyNames={isFetchingCompanyNames}
                    companyNames={companyNames}
                    allRows={allRows}
                    isLoading={isLoading}
                />
            </DialogContent>
        </Dialog>
    );
}
